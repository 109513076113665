export default function MonkeyPaw() {
    return (
        <dl>
            <dt>I wish to see the ghost.</dt>
            <dd>Ghost appears in the current location. After some time it will start hunting from that location.</dd>

            <dt className="mt-4">I wish for activity.</dt>
            <dd>Doubles all ghost activities for two minutes. Breaker turns off and front door locks for that duration.</dd>

            <dt className="mt-4">I wish for sanity.</dt>
            <dd>Everybody's sanity is set to 50%. Ghost moves to a new random favorite room and increases sanity drain by 50%.</dd>

            <dt className="mt-4">I wish the ghost was trapped.</dt>
            <dd>Ghost is locked in its room and can't hunt, roam or do abilities. But locks the player in as well and after that minute the ghost will hunt.</dd>

            <dt className="mt-4">I wish for life.</dt>
            <dd>Revives a dead player. 50% chance to kill the wishing player.</dd>

            <dt className="mt-4">I wish to be safe.</dt>
            <dd>Opens up the nearest blocked hiding spot. Breaks the light in that room and the ghost can sense the player from any distance.</dd>

            <dt className="mt-4">I wish to leave.</dt>
            <dd>Unlocks all exit doors (even during hunt). But the player is blind and slow for five seconds.</dd>

            <dt className="mt-4">I wish for knowledge.</dt>
            <dd>Removes one incorrect evidence from the journal and crosses out associated ghosts. But starts a hunt immediately and the wishing player will have reduced vision and audio for the rest of the round.</dd>

            <dt className="mt-4">I wish for <em>[weather]</em>.</dt>
            <dd>Changes the weather. Drops sanity by 25%.</dd>

            <dt className="mt-4">I wish for anything.</dt>
            <dd>Randomly chooses one of the options above.</dd>
        </dl>
    );
}