import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 707, y: 426 },
    { type: 'Tarot Cards', id: 'tarot', x: 829, y: 239 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 829, y: 239 },
    { type: 'Monkey Paw', id: 'monkey', x: 829, y: 239 },
    { type: 'Haunted Mirror', id: 'mirror', x: 829, y: 239 },
    { type: 'Summoning Circle', id: 'circle', x: 829, y: 239 },
    { type: 'Music Box', id: 'musicbox', x: 829, y: 239 },
    { type: 'Ouija Board', id: 'ouija', x: 829, y: 239 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 238, y: 448 },
    { type: 'Tarot Cards', x: 238, y: 463 },
    { type: 'Voodoo Doll', x: 238, y: 477 },
    { type: 'Monkey Paw', x: 238, y: 504 },
    { type: 'Haunted Mirror', x: 238, y: 519 },
    { type: 'Summoning Circle', x: 238, y: 534 },
    { type: 'Music Box', x: 238, y: 546 },
    { type: 'Ouija Board', x: 238, y: 560 },
];

export default function SunnyMeadowsMaleWing() {
    return <InteractiveMap filename="maps/Sunny_Meadows_Restricted_Map_-_Male_Wing.webp" width={1000} height={666} items={ITEMS} legend={LEGEND} />;
}
