import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Evidence } from "../types/Evidence";
import { sessionArrayState, sessionJsonState, sessionNumberState } from "./SessionStorageRepresentable";
import { RootState } from "./Store";
import { evidenceResolver } from "../types/EvidenceResolver";
import { Ghost, GhostType } from "../types/Ghost";
import { GameOptions, defaultGameOptions } from "../types/GameOptions";
import { StopWatch } from "../types/StopWatch";

interface EvidenceState {
    options: GameOptions;
    evidences: Array<Evidence>;
    ghosts: Array<Ghost>;
    speed: number | null;
    smudgeTimer: StopWatch | null;
    huntTimer: StopWatch | null;
    activeTimer: 'smudge timer' | 'hunt timer' | null;
}

const OPTIONS = sessionJsonState<GameOptions>('options');
const EVIDENCES = sessionArrayState<Evidence>('evidences');
const GHOSTS = sessionArrayState<Ghost>('ghosts');
const SPEED = sessionNumberState('speed');
const SMUDGE_TIMER = sessionJsonState<StopWatch>('smudgeTimer');
const HUNT_TIMER = sessionJsonState<StopWatch>('huntTimer');

function makeEvidenceState(): EvidenceState {
    const anyOptions: any = OPTIONS.read() ?? {};
    const options: GameOptions = { ...defaultGameOptions(), ...anyOptions };
    const evidences = EVIDENCES.read() ?? [];
    const ghosts = GHOSTS.read() ?? [];
    const speed = SPEED.read();
    const smudgeTimer = SMUDGE_TIMER.read();
    const huntTimer = HUNT_TIMER.read();

    return { options, evidences, ghosts, speed, smudgeTimer, huntTimer, activeTimer: null };
}

export const evidenceSlice = createSlice({
    name: 'evidence',
    initialState: makeEvidenceState(),

    reducers: {
        updateOptions: (state, action: PayloadAction<GameOptions>) => {
            const options: GameOptions = { ...defaultGameOptions(), ...action.payload };
            state.options = options;
            OPTIONS.write(options);
        },

        updateEvidence: (state, action: PayloadAction<Evidence>) => {
            const evidence = action.payload;
            const array = [...state.evidences];
            const index = array.findIndex(next => next.type === evidence.type);
            if (index === -1) {
                array.push(evidence);
            } else {
                array[index] = evidence;
            }

            state.evidences = array;
            EVIDENCES.write(array);
        },

        updateSpeed: (state, action: PayloadAction<number>) => {
            const speed = action.payload;
            state.speed = speed;
            SPEED.write(speed);
        },

        toggleTimer: (state) => {
            const current = state.activeTimer;
            if (current === 'smudge timer' && state.huntTimer) {
                state.activeTimer = 'hunt timer';
            } else if (current === 'hunt timer' && state.smudgeTimer) {
                state.activeTimer = 'smudge timer';
            }
        },

        startTimer: (state, action: PayloadAction<'smudge timer' | 'hunt timer'>) => {
            const which = action.payload;
            const start = new Date().getTime();
            const stopWatch: StopWatch = { start };

            if (which === 'smudge timer') {
                state.smudgeTimer = stopWatch;
                state.activeTimer = 'smudge timer';
                SMUDGE_TIMER.write(stopWatch);
            } else if (which === 'hunt timer') {
                state.huntTimer = stopWatch;
                state.activeTimer = 'hunt timer';
                HUNT_TIMER.write(stopWatch);
            }
        },

        endTimer: (state, action: PayloadAction<'smudge timer' | 'hunt timer'>) => {
            const end = new Date().getTime();
            const which = action.payload;
            
            if (which === 'smudge timer') {
                const currentTimer = state.smudgeTimer;
                if (currentTimer) {
                    const stopWatch: StopWatch = { ...currentTimer, end };
                    state.smudgeTimer = stopWatch;
                    state.activeTimer = 'smudge timer';
                    SMUDGE_TIMER.write(stopWatch);
                }
            } else if (which === 'hunt timer') {
                const currentTimer = state.huntTimer;
                if (currentTimer) {
                    const stopWatch: StopWatch = { ...currentTimer, end };
                    state.huntTimer = stopWatch;
                    state.activeTimer = 'hunt timer';
                    HUNT_TIMER.write(stopWatch);
                }
            }
        },

        changeTimerOffset: (state, action: PayloadAction<number>) => {
            const which = state.activeTimer;

            if (which === 'smudge timer') {
                const currentTimer = state.smudgeTimer;
                if (currentTimer) {
                    const offset = (currentTimer.offset ?? 0) + action.payload;
                    const stopWatch: StopWatch = { ...currentTimer, offset };
                    state.smudgeTimer = stopWatch;
                    SMUDGE_TIMER.write(stopWatch);
                }
            } else if (which === 'hunt timer') {
                const currentTimer = state.huntTimer;
                if (currentTimer) {
                    const offset = (currentTimer.offset ?? 0) + action.payload;
                    const stopWatch: StopWatch = { ...currentTimer, offset };
                    state.huntTimer = stopWatch;
                    HUNT_TIMER.write(stopWatch);
                }
            }
        },

        resetSpeedAndTimer: (state) => {
            state.speed = null;
            state.smudgeTimer = null;
            state.huntTimer = null;
            state.activeTimer = null;
            SPEED.clear();
            SMUDGE_TIMER.clear();
            HUNT_TIMER.clear();
        },

        toggleGhost: (state, action: PayloadAction<GhostType>) => {
            const ghostType = action.payload;
            const array = [...state.ghosts];
            const index = array.findIndex(next => next.type === ghostType);
            if (index === -1) {
                const ghost: Ghost = { type: ghostType, status: true };
                array.push(ghost);
            } else {
                const ghost = array[index];
                if (ghost.status === true) {
                    array[index] = { ...ghost, status: false };
                } else if (ghost.status === false) {
                    array[index] = { ...ghost, status: undefined };
                } else {
                    array[index] = { ...ghost, status: true };
                }
            }

            state.ghosts = array;
            GHOSTS.write(array);
        },

        resetEvidences: (state) => {
            state.evidences = [];
            state.ghosts = [];
            state.speed = null;
            state.smudgeTimer = null;
            state.huntTimer = null;
            state.activeTimer = null;
            EVIDENCES.clear();
            GHOSTS.clear();
            SPEED.clear();
            SMUDGE_TIMER.clear();
            HUNT_TIMER.clear();
        },
    },
});

export default evidenceSlice.reducer;
export const { updateOptions, updateEvidence, updateSpeed, toggleTimer, startTimer, endTimer, changeTimerOffset, resetSpeedAndTimer, toggleGhost, resetEvidences } = evidenceSlice.actions;
export const selectOptions = (root: RootState) => root.evidence.options;
export const selectEvidences = (root: RootState) => root.evidence.evidences;
export const selectSpeed = (root: RootState) => root.evidence.speed;
export const selectActiveTimer = (root: RootState) => root.evidence.activeTimer;
export const selectSmudgeTimer = (root: RootState) => root.evidence.smudgeTimer;
export const selectHuntTimer = (root: RootState) => root.evidence.huntTimer;
export const selectGhosts = (root: RootState) => root.evidence.ghosts;
export const selectEvidenceResolver = createSelector(selectEvidences, selectOptions, (evidences, options) => evidenceResolver(options.evidenceCount, evidences));
