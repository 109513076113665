import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Oni() {
    return (
        <div>
            <div>An Oni is more active when players are <em>not</em> alone.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Is more active than other ghosts, especially when people are in the room.</li>
                        <li>Can't perform the <strong>airball</strong> event.</li>
                        <li>Has double sanity drain when colliding with a player.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>During a hunt the ghost is visible for longer.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function OniSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}