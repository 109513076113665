import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 250, y: 410 },
    { type: 'Fusebox', id: 'fusebox2', x: 102, y: 218 },
    { type: 'Fusebox', id: 'fusebox3', x: 692, y: 350 },
    { type: 'Fusebox', id: 'fusebox4', x: 702, y: 392 },
    { type: 'Fusebox', id: 'fusebox5', x: 776, y: 518 },
    { type: 'Switch 1', id: 'switch1', x: 258, y: 490 },
    { type: 'Light 1', id: 'light1', x: 276, y: 462 },
    { type: 'Switch 2', id: 'switch2', x: 215, y: 419 },
    { type: 'Light 2', id: 'light2a', x: 222, y: 460 },
    { type: 'Light 2', id: 'light2b', x: 202, y: 420 },
    { type: 'Switch 3', id: 'switch3', x: 226, y: 358 },
    { type: 'Light 3', id: 'light3a', x: 202, y: 373 },
    { type: 'Light 3', id: 'light3b', x: 75, y: 384 },
    { type: 'Switch 4', id: 'switch4', x: 235, y: 357 },
    { type: 'Light 4', id: 'light4a', x: 303, y: 372 },
    { type: 'Light 4', id: 'light4b', x: 398, y: 384 },
    { type: 'Switch 5', id: 'switch5', x: 233, y: 326 },
    { type: 'Light 5', id: 'light5', x: 230, y: 261 },
    { type: 'Switch 6', id: 'switch6', x: 250, y: 342 },
    { type: 'Light 6', id: 'light6', x: 296, y: 342 },
    { type: 'Switch 7', id: 'switch7', x: 113, y: 218 },
    { type: 'Light 7', id: 'light7a', x: 65, y: 210 },
    { type: 'Light 7', id: 'light7b', x: 594, y: 224 },
    { type: 'Switch 8', id: 'switch8', x: 446, y: 218 },
    { type: 'Light 8', id: 'light8a', x: 401, y: 204 },
    { type: 'Light 8', id: 'light8b', x: 926, y: 224 },
    { type: 'Switch 9', id: 'switch9', x: 701, y: 368 },
    { type: 'Light 9', id: 'light9', x: 721, y: 362 },
    { type: 'Tarot Cards', id: 'tarot', x: 264, y: 517 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 264, y: 517 },
    { type: 'Monkey Paw', id: 'monkey', x: 264, y: 517 },
    { type: 'Haunted Mirror', id: 'mirror', x: 280, y: 444 },
    { type: 'Summoning Circle', id: 'circle', x: 276, y: 416 },
    { type: 'Music Box', id: 'musicbox', x: 264, y: 517 },
    { type: 'Ouija Board', id: 'ouija', x: 264, y: 517 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 532, y: 402 },
    { type: 'Light 1', x: 258, y: 490 },
    { type: 'Light 2', x: 215, y: 419 },
    { type: 'Light 3', x: 226, y: 358 },
    { type: 'Light 4', x: 235, y: 357 },
    { type: 'Light 5', x: 233, y: 326 },
    { type: 'Light 6', x: 250, y: 342 },
    { type: 'Light 7', x: 113, y: 218 },
    { type: 'Light 8', x: 446, y: 218 },
    { type: 'Light 9', x: 701, y: 368 },
    { type: 'Switch 1', x: 532, y: 412 },
    { type: 'Switch 2', x: 532, y: 412 },
    { type: 'Switch 3', x: 532, y: 412 },
    { type: 'Switch 4', x: 532, y: 412 },
    { type: 'Switch 5', x: 532, y: 412 },
    { type: 'Switch 6', x: 532, y: 412 },
    { type: 'Switch 7', x: 532, y: 412 },
    { type: 'Switch 8', x: 532, y: 412 },
    { type: 'Switch 9', x: 532, y: 412 },
    { type: 'Tarot Cards', x: 532, y: 421 },
    { type: 'Voodoo Doll', x: 532, y: 432 },
    { type: 'Monkey Paw', x: 532, y: 442 },
    { type: 'Haunted Mirror', x: 532, y: 452 },
    { type: 'Summoning Circle', x: 532, y: 461 },
    { type: 'Music Box', x: 532, y: 472 },
    { type: 'Ouija Board', x: 532, y: 481 },
];

export default function Prison() {
    return <InteractiveMap filename="maps/Prison_-_Floorplan.webp" width={1000} height={575} items={ITEMS} legend={LEGEND} radius={5} />;
}
