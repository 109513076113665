import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Poltergeist() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Throws stuff around all the time.</li>
                        <li>Can throw farther than other ghosts.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    May throw multiple things at once, draining sanity of people standing nearby and 
                    generating EMF 2 (plus EMF 3 at the items thrown).
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function PoltergeistSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}