import { Provider } from "react-redux";
import { store } from "./redux/Store";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

import Content from "./Content";

export default function App() {
	return (
		<div className="container-fluid vh-100">
			<Provider store={store}>
				<Content />
			</Provider>
		</div>
	);
}