import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 492, y: 651 },
    { type: 'Fusebox', id: 'fusebox2', x: 483, y: 443 },
    { type: 'Fusebox', id: 'fusebox3', x: 613, y: 326 },
    { type: 'Fusebox', id: 'fusebox4', x: 496, y: 278 },
    { type: 'Fusebox', id: 'fusebox5', x: 536, y: 120 },
    { type: 'Tarot Cards', id: 'tarot', x: 564, y: 314 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 564, y: 314 },
    { type: 'Monkey Paw', id: 'monkey', x: 564, y: 314 },
    { type: 'Haunted Mirror', id: 'mirror', x: 564, y: 314 },
    { type: 'Summoning Circle', id: 'circle', x: 564, y: 314 },
    { type: 'Music Box', id: 'musicbox', x: 564, y: 314 },
    { type: 'Ouija Board', id: 'ouija', x: 564, y: 314 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 670, y: 141 },
    { type: 'Tarot Cards', x: 670, y: 150 },
    { type: 'Voodoo Doll', x: 670, y: 160 },
    { type: 'Monkey Paw', x: 670, y: 170 },
    { type: 'Haunted Mirror', x: 670, y: 190 },
    { type: 'Summoning Circle', x: 670, y: 200 },
    { type: 'Music Box', x: 670, y: 210 },
    { type: 'Ouija Board', x: 670, y: 220 },
];

export default function SunnyMeadowsFull() {
    return <InteractiveMap filename="maps/Sunny_Meadows_Mental_Institution_-_Full_Map_Floorplan.webp" width={1000} height={731} items={ITEMS} legend={LEGEND} />;
}
