import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Banshee() {
    return (
        <div>
            <div>At the start of the game one player is chosen to be the hunt target. If that player dies, a new player is targeted.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /> &mdash; will walk towards the target instead of the nearest player</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Focuses on the same player until they are dead.</li>
                        <li>Event with singing towards the target.</li>
                        <li>Frequently roams towards the target player.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    33% chance to screech instead of singing when using a <strong>paramic</strong>.
                    Will weaken the target before hunting.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <SanityIndicator sanity={50} /> or lower, but only considers the target's sanity.</li>
                        <li>Only hunts the targeted person, others will be ignored. If the targeted player is outside the house, it may hunt anybody.</li>
                        <li>When a non-targeted person is smudging the ghost, it works normally, but does <strong>not</strong> count towards the "repel ghost" objective, unless the target is within range.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function BansheeSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />;
}