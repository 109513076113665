import { ALL_PRIMARY_EVIDENCE_TYPES } from "../types/Evidence";
import { resetEvidences } from "../redux/EvidenceSlice";
import { useAppDispatch } from "../redux/Hooks";

import EvidendeIndicator from "./EvidenceIndicator";

export default function PrimaryEvidenceList() {
    const dispatch = useAppDispatch();

    const reset = () => dispatch(resetEvidences());

    const listItems = ALL_PRIMARY_EVIDENCE_TYPES.map(evidenceType => {
        return (
            <li className="list-group-item" key={evidenceType}>
                <EvidendeIndicator evidenceType={evidenceType} />
            </li>
        );
    });

    return (
        <section className="card m-1 w-100">
            <header className="card-header">
                <h5 className="card-title">Evidence</h5>
            </header>
            <main>
                <ul className="list-group list-group-flush">{listItems}</ul>
            </main>
            <footer className="card-footer">
                <button type="button" className="btn btn-primary" onClick={reset}><i className="bi-arrow-counterclockwise"></i> Reset</button>
            </footer>
        </section>
    );
}