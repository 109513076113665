import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 436, y: 781 },
    { type: 'Fusebox', id: 'fusebox2', x: 733, y: 668 },
    { type: 'Key', id: 'key', x: 333, y: 771 },
    { type: 'Tarot Cards', id: 'tarot', x: 319, y: 769 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 201, y: 414 },
    { type: 'Monkey Paw', id: 'monkey', x: 383, y: 269 },
    { type: 'Haunted Mirror', id: 'mirror', x: 332, y: 752 },
    { type: 'Summoning Circle', id: 'circle', x: 700, y: 656 },
    { type: 'Music Box', id: 'musicbox', x: 263, y: 713 },
    { type: 'Ouija Board', id: 'ouija', x: 222, y: 614 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 615, y: 225 },
    { type: 'Key', x: 615, y: 252 },
    { type: 'Tarot Cards', x: 615, y: 277 },
    { type: 'Voodoo Doll', x: 615, y: 306 },
    { type: 'Monkey Paw', x: 615, y: 334 },
    { type: 'Haunted Mirror', x: 615, y: 360 },
    { type: 'Summoning Circle', x: 615, y: 387 },
    { type: 'Music Box', x: 615, y: 414 },
    { type: 'Ouija Board', x: 615, y: 442 },
];

export default function EdgefieldRoad() {
    return <InteractiveMap filename="maps/42_Edgefield_Road_-_Floorplan.webp" width={1000} height={882} items={ITEMS} legend={LEGEND} />;
}