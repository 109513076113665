import { GhostType } from "./Ghost";

export type EvidenceCount = 3 | 2 | 1 | 0;

export type PrimaryEvidenceType
    = 'D.O.T.S.'
    | 'EMF 5'
    | 'Freezing'
    | 'Ghost Orbs'
    | 'Ghost Writing'
    | 'Ultraviolet'
    | 'Spirit Box';

export const ALL_PRIMARY_EVIDENCE_TYPES: Array<PrimaryEvidenceType> = [
    'D.O.T.S.',
    'EMF 5',
    'Freezing',
    'Ghost Orbs',
    'Ghost Writing',
    'Ultraviolet',
    'Spirit Box',
];

export type SecondaryEvidenceType
    = 'Early Hunt'
    | 'Change Ghost Room'
    | 'Turn on Lights'
    | 'Turn on Breaker'
    | 'Turn off Breaker'
    | 'Multiple Handprints'
    | 'Six-Finger Handprint'
    | 'Airball Event'
    | 'Disturbed Salt'
    | 'Spirit Box: Heavy Breathing'
    | 'Paramic: Screech Sound';

export const ALL_SECONDARY_EVIDENCE_TYPES: Array<SecondaryEvidenceType> = [
    'Early Hunt',
    'Change Ghost Room',
    'Turn on Lights',
    'Turn on Breaker',
    'Turn off Breaker',
    'Multiple Handprints',
    'Six-Finger Handprint',
    'Airball Event',
    'Disturbed Salt',
    'Spirit Box: Heavy Breathing',
    'Paramic: Screech Sound',
];    

export interface Evidence {
    type: PrimaryEvidenceType | SecondaryEvidenceType;
    status: boolean | undefined;
}

export function primaryEvidenceTypes(ghostType: GhostType): Array<PrimaryEvidenceType> {
    switch (ghostType) {
        case 'Banshee':
            return ['Ultraviolet', 'Ghost Orbs', 'D.O.T.S.'];
        case 'Demon':
            return ['Ultraviolet', 'Ghost Writing', 'Freezing'];
        case 'Deogen':
            return ['Spirit Box', 'Ghost Writing', 'D.O.T.S.'];
        case 'Goryo':
            return ['EMF 5', 'Ultraviolet', 'D.O.T.S.'];
        case 'Hantu':
            return ['Ultraviolet', 'Ghost Orbs', 'Freezing'];
        case 'Jinn':
            return ['EMF 5', 'Ultraviolet', 'Freezing'];
        case 'Mare':
            return ['Spirit Box', 'Ghost Orbs', 'Ghost Writing'];
        case 'Mimic':
            return ['Spirit Box', 'Ultraviolet', 'Freezing'];
        case 'Moroi':
            return ['Spirit Box', 'Ghost Writing', 'Freezing'];
        case 'Myling':
            return ['EMF 5', 'Ultraviolet', 'Ghost Writing'];
        case 'Obake':
            return ['EMF 5', 'Ultraviolet', 'Ghost Orbs'];
        case 'Oni':
            return ['EMF 5', 'Freezing', 'D.O.T.S.'];
        case 'Onryo':
            return ['Spirit Box', 'Ghost Orbs', 'Freezing'];
        case 'Phantom':
            return ['Spirit Box', 'Ultraviolet', 'D.O.T.S.'];
        case 'Poltergeist':
            return ['Spirit Box', 'Ultraviolet', 'Ghost Writing'];
        case 'Raiju':
            return ['EMF 5', 'Ghost Orbs', 'D.O.T.S.'];
        case 'Revenant':
            return ['Ghost Orbs', 'Ghost Writing', 'Freezing'];
        case 'Shade':
            return ['EMF 5', 'Ghost Writing', 'Freezing'];
        case 'Spirit':
            return ['EMF 5', 'Spirit Box', 'Ghost Writing'];
        case 'Thaye':
            return ['Ghost Orbs', 'Ghost Writing', 'D.O.T.S.'];
        case 'Twins':
            return ['EMF 5', 'Spirit Box', 'Freezing'];
        case 'Wraith':
            return ['EMF 5', 'Spirit Box', 'D.O.T.S.'];
        case 'Yokai':
            return ['Spirit Box', 'Ghost Orbs', 'D.O.T.S.'];
        case 'Yurei':
            return ['Ghost Orbs', 'Freezing', 'D.O.T.S.'];
    }
}

export function getForcedEvidenceType(ghostType: GhostType): PrimaryEvidenceType | undefined {
    switch (ghostType) {
        case 'Deogen': return 'Spirit Box';
        case 'Goryo': return 'D.O.T.S.';
        case 'Hantu': return 'Freezing';
        case 'Moroi': return 'Spirit Box';
        case 'Obake': return 'Ultraviolet';
    }

    return undefined;
}

export function getRuledOutPrimaryEvidenceTypes(evidences: Array<Evidence>): Set<PrimaryEvidenceType> {
    return new Set(evidences
        .filter(evidence => evidence.status === false)
        .filter(evidence => ALL_PRIMARY_EVIDENCE_TYPES.includes(evidence.type as any))
        .map(evidence => evidence.type as PrimaryEvidenceType));
}

export function getFoundPrimaryEvidenceTypes(evidences: Array<Evidence>): Set<PrimaryEvidenceType> {
    return new Set(evidences
        .filter(evidence => evidence.status === true)
        .filter(evidence => ALL_PRIMARY_EVIDENCE_TYPES.includes(evidence.type as any))
        .map(evidence => evidence.type as PrimaryEvidenceType));
}

export function makeTuples(evidenceTypes: Array<PrimaryEvidenceType>): Array<[PrimaryEvidenceType, PrimaryEvidenceType]> {
    const tuples: Array<[PrimaryEvidenceType, PrimaryEvidenceType]> = [];

    const isValidTuple = (x: PrimaryEvidenceType, y: PrimaryEvidenceType) => {
        return x !== y;
    };

    const containsTuple = (x: PrimaryEvidenceType, y: PrimaryEvidenceType) => {
        for (let index = 0; index < tuples.length; ++index) {
            const [a, b] = tuples[index];
            const ab = [a, b].sort().join('+');
            const xy = [x, y].sort().join('+');

            if (ab === xy) {
                return true;
            }
        }
        return false;
    };

    for (let i = 0; i < evidenceTypes.length; ++i) {
        for (let j = 0; j < evidenceTypes.length; ++j) {
            const I = evidenceTypes[i];
            const J = evidenceTypes[j];

            if (isValidTuple(I, J) && !containsTuple(I, J)) {
                tuples.push([I, J]);
            }
        }
    }

    return tuples;
}

export function makeTriples(evidenceTypes: Array<PrimaryEvidenceType>): Array<[PrimaryEvidenceType, PrimaryEvidenceType, PrimaryEvidenceType]> {
    const triples: Array<[PrimaryEvidenceType, PrimaryEvidenceType, PrimaryEvidenceType]> = [];

    const isValidTriple = (x: PrimaryEvidenceType, y: PrimaryEvidenceType, z: PrimaryEvidenceType) => {
        return x !== y && x !== z && y !== z;
    };

    const containsTriple = (x: PrimaryEvidenceType, y: PrimaryEvidenceType, z: PrimaryEvidenceType) => {
        for (let index = 0; index < triples.length; ++index) {
            const [a, b, c] = triples[index];
            const abc = [a, b, c].sort().join('+');
            const xyz = [x, y, z].sort().join('+');

            if (abc === xyz) {
                return true;
            }
        }
        return false;
    };

    for (let i = 0; i < evidenceTypes.length; ++i) {
        for (let j = 0; j < evidenceTypes.length; ++j) {
            for (let k = 0; k < evidenceTypes.length; ++k) {
                const I = evidenceTypes[i];
                const J = evidenceTypes[j];
                const K = evidenceTypes[k];

                if (isValidTriple(I, J, K) && !containsTriple(I, J, K)) {
                    triples.push([I, J, K]);
                }
            }
        }
    }

    return triples;
}
