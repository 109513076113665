import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Obake() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /> <sup>1</sup></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                    </ul>
                    <div>
                        <sup>1</sup> When playing with 1 or 2 evidence, UV evidence is guaranteed to be there due 
                        to the ghost's ability.
                    </div>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    <ul>
                        <li>Has only a 75% chance of producing UV evidence, instead of 100%.</li>
                        <li>Has a 16.7% chance of producing a unique UV evidence:
                            <ul>
                                <li>six-fingered handprint, or</li>
                                <li>two handprints on light switch instead of one, or</li>
                                <li>five handprints on keyboards and prison cell doors instead of four.</li>
                            </ul>
                        </li>
                        <li>UV evidence can disappear more quickly than usual.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Can shapeshift during a hunt (cannot be seen by dead players).</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function ObakeSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}