import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { endTimer, selectActiveTimer, selectSmudgeTimer, startTimer } from "../redux/EvidenceSlice";
import { StopWatch, calculateTimeInSeconds, formatStopWatch } from "../types/StopWatch";

export default function SmudgeTimer() {
    const dispatch = useAppDispatch();
    const timer = useAppSelector(selectSmudgeTimer);
    const active = useAppSelector(selectActiveTimer) === 'smudge timer';
    const [counter, setCounter] = useState(0);

    const handleKey: EventListener = useCallback((event) => {
        if ('key' in event) {
            const key = `${event.key}`.toLowerCase();
            
            if (key === 's') {
                if (timer === null || !!timer.end) {
                    dispatch(startTimer('smudge timer'));
                } else {
                    dispatch(endTimer('smudge timer'));
                }
            }
        }
    }, [dispatch, timer]);

    useEffect(() => {
        window.addEventListener('keydown', handleKey);
        return () => window.removeEventListener('keydown', handleKey);
    }, [handleKey]);

    useEffect(() => {
        const timer = setTimeout(() => setCounter(counter + 1), 1000);
        return () => clearTimeout(timer);
    }, [counter]);

    return (
        <div className="text-end">
            <FormattedTimer timer={timer} active={active} />
            <SmudgeHint timer={timer} />
        </div>
    );
}

function FormattedTimer({ timer, active }: { timer: StopWatch | null, active: boolean }) {
    if (timer) {
        const formattedTimer = formatStopWatch(timer, false);
        const formattedAdjustedTimer = formatStopWatch(timer, true);
        const offset = timer.offset ?? 0;

        return (
            <div className="fw-lighter">
                {active && <i className="bi-chevron-right"></i> }
                <small className="me-2 text-muted fs-6">Smudge</small>
                {offset !== 0 && <span>{formattedTimer}</span>}
                {offset !== 0 && <span className="text-warning ms-1 me-1">{offset > 0 ? `+${offset} s ` : `${offset} s `}</span>}
                {offset !== 0 && <span className="me-2">=</span>}
                <span>{formattedAdjustedTimer}</span>
            </div>
        );
    }
    
    return null;
}

function SmudgeHint({ timer }: { timer: StopWatch | null }) {
    if (!!timer) {
        const seconds = calculateTimeInSeconds(timer);
        if (seconds >= 60 && seconds < 90) {
            if (!!timer.end) {
                return <div className="text-danger fw-lighter"><small>Demon</small></div>
            } else {
                return <div className="text-warning fw-lighter"><small>possible Demon</small></div>
            }
        } else if (seconds > 180) {
            return <div className="text-info fw-lighter"><small>possible Spirit</small></div>
        }
    }

    return null;
}