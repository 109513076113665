import { ALL_SECONDARY_EVIDENCE_TYPES, Evidence, PrimaryEvidenceType, SecondaryEvidenceType } from "./Evidence";
import { EvidenceResolver } from "./EvidenceResolver";
import { ALL_GHOST_TYPES, GhostType } from "./Ghost";

export class SecondaryEvidenceResolver implements EvidenceResolver {

    private readonly evidences: Array<Evidence>;
    private readonly ghosts: Map<GhostType, boolean>;

    constructor(evidences: Array<Evidence>) {
        this.evidences = evidences;

        const ghosts = new Map<GhostType, boolean>(ALL_GHOST_TYPES.map(ghostType => ([ghostType, true])));
        const resolvers = new Map<SecondaryEvidenceType, (ghostType: GhostType) => boolean | undefined>(ALL_SECONDARY_EVIDENCE_TYPES.map(evidenceType => ([evidenceType, secondaryEvidence(evidenceType, evidences)])));

        for (const ghostType of ALL_GHOST_TYPES) {
            for (const evidenceType of ALL_SECONDARY_EVIDENCE_TYPES) {
                const resolver = resolvers.get(evidenceType);
                const status = resolver ? resolver(ghostType) : undefined;
                if (status === false) {
                    // `false` on a secondary evidence should rule out a ghost.
                    ghosts.set(ghostType, false);
                }
            }
        }

        for (const evidenceType of ALL_SECONDARY_EVIDENCE_TYPES) {
            const resolver = resolvers.get(evidenceType);
            const enabledGhosts = new Set<GhostType>();

            for (const ghostType of ALL_GHOST_TYPES) {
                const status = resolver ? resolver(ghostType) : undefined;
                if (status === true) {
                    // `true` on a secondary evidence should rule out all other ghosts.
                    enabledGhosts.add(ghostType);
                }
            }

            if (enabledGhosts.size > 0) {
                // Rule out all other ghosts.
                ALL_GHOST_TYPES
                    .filter(next => !enabledGhosts.has(next))
                    .forEach(ghostType => ghosts.set(ghostType, false));
            }
        }

        this.ghosts = ghosts;
    }

    resolveEvidence(evidenceType: PrimaryEvidenceType | SecondaryEvidenceType): boolean | undefined {
        if (ALL_SECONDARY_EVIDENCE_TYPES.includes(evidenceType as any)) {
            return this.resolveSedondaryEvidence(evidenceType as SecondaryEvidenceType);
        } else {
            return undefined;
        }
    }

    resolveSedondaryEvidence(evidenceType: SecondaryEvidenceType): boolean | undefined {
        const evidence = this.evidences.find(next => next.type === evidenceType);
        return evidence?.status;
    }

    resolveGhost(ghostType: GhostType): boolean {
        return this.ghosts.get(ghostType) ?? false;
    }

}

function huntedEarly(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const earlyHunters = new Set<GhostType>(['Demon', 'Mare', 'Onryo', 'Raiju', 'Thaye', 'Yokai', 'Mimic']);
    const huntedEarly = evidences.find(next => next.type === 'Early Hunt')?.status === true;

    if (huntedEarly) {
        return (ghostType) => earlyHunters.has(ghostType);
    } else {
        return (ghostType) => undefined;
    }
}

function changedGhostRoom(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const changedGhostRoom = evidences.find(next => next.type === 'Change Ghost Room')?.status === true;
    if (changedGhostRoom) {
        return (ghostType) => ghostType !== 'Goryo';
    } else {
        return (ghostType) => undefined;
    }
}

function turnedOnLights(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const turnedOnLights = evidences.find(next => next.type === 'Turn on Lights')?.status === true;
    if (turnedOnLights) {
        return (ghostType) => ghostType !== 'Mare';
    } else  {
        return (ghostType) => undefined;
    }
}

function turnedOnBreaker(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const turnedOnBreaker = evidences.find(next => next.type === 'Turn on Breaker')?.status === true;
    if (turnedOnBreaker) {
        return (ghostType) => ghostType !== 'Hantu';
    } else {
        return (ghostType) => undefined;
    }
}

function turnedOffBreaker(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const turnedOffBreaker = evidences.find(next => next.type === 'Turn off Breaker')?.status === true;
    if (turnedOffBreaker) {
        return (ghostType) => ghostType !== 'Jinn';
    } else {
        return (ghostType) => undefined;
    }
}

function foundMultipleHandprints(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const foundMultipleHandprints = evidences.find(next => next.type === 'Multiple Handprints')?.status === true;
    if (foundMultipleHandprints) {
        return (ghostType) => ghostType === 'Obake' || ghostType === 'Mimic';
    } else {
        return (ghostType) => undefined;
    }
}

function foundSixFingerHandprint(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const foundSixFingerHandprint = evidences.find(next => next.type === 'Six-Finger Handprint')?.status === true;
    if (foundSixFingerHandprint) {
        return (ghostType) => ghostType === 'Obake' || ghostType === 'Mimic';
    } else {
        return (ghostType) => undefined;
    }
}

function heardAirballEvent(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const heardAirballEvent = evidences.find(next => next.type === 'Airball Event')?.status === true;
    if (heardAirballEvent) {
        return (ghostType) => ghostType !== 'Oni';
    } else {
        return (ghostType) => undefined;
    }
}

function foundDisturbedSalt(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const foundDisturbedSalt = evidences.find(next => next.type === 'Disturbed Salt')?.status === true;
    if (foundDisturbedSalt) {
        return (ghostType) => ghostType !== 'Wraith';
    } else {
        return (ghostType) => undefined;
    }
}

function heardHeavyBreathing(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const heardHeavyBreathing = evidences.find(next => next.type === 'Spirit Box: Heavy Breathing')?.status === true;
    if (heardHeavyBreathing) {
        return (ghostType) => ghostType === 'Deogen' || ghostType === 'Mimic';
    } else {
        return (ghostType) => undefined;
    }
}

function heardParamicScreechSound(evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const heardParamicScreechSound = evidences.find(next => next.type === 'Paramic: Screech Sound')?.status === true;
    if (heardParamicScreechSound) {
        return (ghostType) => ghostType === 'Banshee' || ghostType === 'Mimic';
    } else {
        return (ghostType) => undefined;
    }
}

function secondaryEvidence(evidenceType: SecondaryEvidenceType, evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    switch (evidenceType) {
        case 'Early Hunt':
            return huntedEarly(evidences);
        case 'Change Ghost Room':
            return changedGhostRoom(evidences);
        case 'Turn on Lights':
            return turnedOnLights(evidences);
        case 'Turn on Breaker':
            return turnedOnBreaker(evidences);
        case 'Turn off Breaker':
            return turnedOffBreaker(evidences);
        case 'Multiple Handprints':
            return foundMultipleHandprints(evidences);
        case 'Six-Finger Handprint':
            return foundSixFingerHandprint(evidences);
        case 'Airball Event':
            return heardAirballEvent(evidences);
        case 'Disturbed Salt':
            return foundDisturbedSalt(evidences);
        case 'Spirit Box: Heavy Breathing':
            return heardHeavyBreathing(evidences);
        case 'Paramic: Screech Sound':
            return heardParamicScreechSound(evidences);
    }

    return (ghostType) => undefined;
}
