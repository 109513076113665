import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 44, y: 150 },
    { type: 'Fusebox', id: 'fusebox2', x: 333, y: 168 },
    { type: 'Fusebox', id: 'fusebox3', x: 651, y: 216 },
    { type: 'Tarot Cards', id: 'tarot', x: 85, y: 327 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 462, y: 352 },
    { type: 'Monkey Paw', id: 'monkey', x: 224, y: 326 },
    { type: 'Haunted Mirror', id: 'mirror', x: 135, y: 271 },
    { type: 'Summoning Circle', id: 'circle', x: 689, y: 175 },
    { type: 'Music Box', id: 'musicbox', x: 188, y: 315 },
    { type: 'Ouija Board', id: 'ouija', x: 332, y: 151 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 769, y: 172 },
    { type: 'Tarot Cards', x: 769, y: 190 },
    { type: 'Voodoo Doll', x: 769, y: 212 },
    { type: 'Monkey Paw', x: 769, y: 233 },
    { type: 'Haunted Mirror', x: 769, y: 251 },
    { type: 'Summoning Circle', x: 769, y: 271 },
    { type: 'Music Box', x: 769, y: 291 },
    { type: 'Ouija Board', x: 769, y: 308 },
];

export default function GraftonFarmhouse() {
    return <InteractiveMap filename="maps/Grafton_Farmhouse_-_Floorplan.webp" width={1000} height={473} items={ITEMS} legend={LEGEND} />;
}