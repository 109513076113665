import { configureStore } from "@reduxjs/toolkit";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

import evidence from "./EvidenceSlice";

export const store = configureStore({
    reducer: {
        evidence,
    },

    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        createStateSyncMiddleware(),
    ],
});

initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const selectRootState = (root: RootState) => root;
