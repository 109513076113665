import { selectOptions, updateOptions } from "../redux/EvidenceSlice";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { EvidenceCount } from "../types/Evidence";

export default function EvidenceOptions() {
    const dispatch = useAppDispatch();
    const options = useAppSelector(selectOptions);

    const setEvidenceCount = (evidenceCount: EvidenceCount) => dispatch(updateOptions({ ...options, evidenceCount }));
    const setSpeedFactor = (speedFactor: number) => dispatch(updateOptions({ ...options, speedFactor }));

    return (
        <section className="card m-1 w-100">
            <header className="card-header">
                <h5 className="card-title">Options</h5>
            </header>
            <main>
                <div className="m-2">
                    <label className="form-label">Evidence Count</label>
                    <div className="text-center">
                        <div className="btn-group w-100">
                            <button type="button" className={options.evidenceCount === 3 ? "btn btn-primary active" : "btn btn-primary"} onClick={() => setEvidenceCount(3)}>3</button>
                            <button type="button" className={options.evidenceCount === 2 ? "btn btn-primary active" : "btn btn-primary"} onClick={() => setEvidenceCount(2)}>2</button>
                            <button type="button" className={options.evidenceCount === 1 ? "btn btn-primary active" : "btn btn-primary"} onClick={() => setEvidenceCount(1)}>1</button>
                            <button type="button" className={options.evidenceCount === 0 ? "btn btn-primary active" : "btn btn-primary"} onClick={() => setEvidenceCount(0)}>0</button>
                        </div>
                    </div>
                </div>
                <div className="m-2">
                    <label htmlFor="speedFactorInput" className="form-label">Speed Factor</label>
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <input type="range" id="speedFactorInput" className="form-range ms-1 me-2" min={0.1} max={2} step={0.1} defaultValue={options.speedFactor} onChange={({target}) => setSpeedFactor(target.valueAsNumber)} />
                        <span>{(options.speedFactor * 100).toFixed(0) + '%'}</span>
                    </div>
                </div>
            </main>
        </section>
    );
}