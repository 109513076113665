import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 537, y: 587 },
    { type: 'Fusebox', id: 'fusebox2', x: 297, y: 555 },
    { type: 'Key', id: 'key', x: 581, y: 674 },
    { type: 'Tarot Cards', id: 'tarot', x: 667, y: 642 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 821, y: 187 },
    { type: 'Monkey Paw', id: 'monkey', x: 681, y: 350 },
    { type: 'Haunted Mirror', id: 'mirror', x: 397, y: 493 },
    { type: 'Summoning Circle', id: 'circle', x: 113, y: 483 },
    { type: 'Music Box', id: 'musicbox', x: 596, y: 675 },
    { type: 'Ouija Board', id: 'ouija', x: 451, y: 469 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 774, y: 490 },
    { type: 'Key', x: 774, y: 509 },
    { type: 'Tarot Cards', x: 774, y: 528 },
    { type: 'Voodoo Doll', x: 774, y: 550 },
    { type: 'Monkey Paw', x: 774, y: 570 },
    { type: 'Haunted Mirror', x: 774, y: 588 },
    { type: 'Summoning Circle', x: 774, y: 608 },
    { type: 'Music Box', x: 774, y: 627 },
    { type: 'Ouija Board', x: 774, y: 646 },
];

export default function WillowStreet() {
    return <InteractiveMap filename="maps/13_Willow_Street_-_Floorplan.webp" width={1000} height={792} items={ITEMS} legend={LEGEND} />;
}