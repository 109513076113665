import { MapName, ALL_MAP_NAMES } from "../types/MapName";

import TanglewoodDrive from "./TanglewoodDrive";
import RidgeviewCourt from "./RidgeviewCourt";
import WillowStreet from "./WillowStreet";
import EdgefieldRoad from "./EdgefieldRoad";
import BleasdaleFarmhouse from "./BleasdaleFarmhouse";
import CampWoodwind from "./CampWoodwind";
import GraftonFarmhouse from "./GraftonFarmhouse";
import SunnyMeadowsFull from "./SunnyMeadowsFull";
import SunnyMeadowsCourtyard from "./SunnyMeadowsCourtyard";
import SunnyMeadowsFemaleWing from "./SunnyMeadowsFemaleWing";
import SunnyMeadowsMaleWing from "./SunnyMeadowsMaleWing";
import SunnyMeadowsHospitalWing from "./SunnyMeadowsHospitalWing";
import SunnyMeadowsRestrictedWing from "./SunnyMeadowsRestrictedWing";
import MapleLodgeCampsite from "./MapleLodgeCampsite";
import Prison from "./Prison";
import BrownstoneHighSchool from "./BrownstoneHighSchool";

export default function Maps({ mapName, onChange }: { mapName: MapName | '', onChange: (mapName: MapName | '') => void }) {
    return (
        <div className="card">
            <select className="form-select" defaultValue={mapName} onChange={({target}) => onChange(target.value as (MapName | ''))}>
                <option value="">Location...</option>
                {ALL_MAP_NAMES.map(name => <option key={name} value={name}>{name}</option>)}
            </select>

            {mapName === '' && <div className="p-4 text-muted">No location selected.</div>}
            {mapName === '6 Tanglewood Drive' && <TanglewoodDrive />}
            {mapName === '10 Ridgeview Court' && <RidgeviewCourt />}
            {mapName === '13 Willow Street' && <WillowStreet />}
            {mapName === '42 Edgefield Road' && <EdgefieldRoad />}
            {mapName === 'Bleasdale Farmhouse' && <BleasdaleFarmhouse />}
            {mapName === 'Camp Woodwind' && <CampWoodwind />}
            {mapName === 'Grafton Farmhouse' && <GraftonFarmhouse />}
            {mapName === 'Sunny Meadows (Full)' && <SunnyMeadowsFull />}
            {mapName === 'Sunny Meadows: Courtyard (Restricted)' && <SunnyMeadowsCourtyard />}
            {mapName === 'Sunny Meadows: Female Wing (Restricted)' && <SunnyMeadowsFemaleWing />}
            {mapName === 'Sunny Meadows: Male Wing (Restricted)' && <SunnyMeadowsMaleWing />}
            {mapName === 'Sunny Meadows: Hospital Wing (Restricted)' && <SunnyMeadowsHospitalWing />}
            {mapName === 'Sunny Meadows: Restricted Wing (Restricted)' && <SunnyMeadowsRestrictedWing />}
            {mapName === 'Maple Lodge Campsite' && <MapleLodgeCampsite />}
            {mapName === 'Prison' && <Prison />}
            {mapName === 'Brownstone High School' && <BrownstoneHighSchool />}
        </div>
    );
}
