import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly, speedOr } from "../SpeedIndicator";
import SpeedSymbol from "../SpeedSymbol";

export default function Revenant() {
    return (
        <div>
            <div className="ghost-details">
                <div>A Revenant is usually slow when roaming, but gets extremely fast when hunting a target. The only chance is to hide.</div>
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li><RevenantSpeedIndicator /> When it finds a target during a hunt it runs extremely fast.</li>
                        <li>Slows down slowly if it loses it's target.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function RevenantSpeedIndicator() {
    return (
        <>
        <SpeedSymbol speed={1.0} description="very slow when roaming" speedingUp />
        <SpeedSymbol speed={3.0} description="very fast when detected a player" keepingSpeed />
        <SpeedIndicator speed={speedOr(speedExactly(1.0), speedExactly(3.0, .5))} />
        </>
    );
}
