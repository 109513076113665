import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 92, y: 282 },
    { type: 'Fusebox', id: 'fusebox2', x: 370, y: 284 },
    { type: 'Tarot Cards', id: 'tarot', x: 219, y: 364 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 248, y: 316 },
    { type: 'Monkey Paw', id: 'monkey', x: 266, y: 363 },
    { type: 'Haunted Mirror', id: 'mirror', x: 266, y: 355 },
    { type: 'Summoning Circle', id: 'circle', x: 247, y: 332 },
    { type: 'Music Box', id: 'musicbox', x: 275, y: 370 },
    { type: 'Ouija Board', id: 'ouija', x: 235, y: 355 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 709, y: 184 },
    { type: 'Tarot Cards', x: 709, y: 192 },
    { type: 'Voodoo Doll', x: 709, y: 202 },
    { type: 'Monkey Paw', x: 709, y: 211 },
    { type: 'Haunted Mirror', x: 709, y: 220 },
    { type: 'Summoning Circle', x: 709, y: 229 },
    { type: 'Music Box', x: 709, y: 237 },
    { type: 'Ouija Board', x: 709, y: 246 },
];

export default function BrownstoneHighSchool() {
    return <InteractiveMap filename="maps/Brownstone_High_School_-_Floorplan.webp" width={1000} height={476} items={ITEMS} legend={LEGEND} radius={5} />;
}
