import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly, speedInRange, speedOr } from "../SpeedIndicator";
import SpeedSymbol from "../SpeedSymbol";

export default function Moroi() {
    return (
        <div>
            <div>Curses players and can be very fast.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /> <sup>1</sup></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                    </ul>
                    <div>
                        <sup>1</sup> When playing with 1 or 2 evidence, spirit box is guaranteed to be there due 
                        to the ghost's ability.
                    </div>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    <ul>
                        <li>Can <strong>curse</strong> a player, lowering their sanity at double rate. Cursing happens when
                            <ul>
                                <li>the player gets a response through <EvidendeIndicator evidenceType="Spirit Box" /></li>
                                <li>the player hears a whisper on their <strong>paramic</strong></li>
                            </ul>
                        </li>
                        <li>Taking medicine will remove the curse.</li>
                        <li>Multiple people can get cursed.</li>
                        <li>Tip: somebody uses the spirit box, another watches the sanity monitor. If sanity drops drastically on a response, it's a Moroi.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li><MoroiSpeedIndicator /> Moroi is faster depending on how low the average sanity is, and really fast when in line-of-sight.</li>
                        <li>Smudging it will blind it for 7.5s instead of 5s.</li>
                        <li>Tip: take sanity pills during a hunt and listen to the speed. If it's getting slower, it's probably a Moroi or a Revenant.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function MoroiSpeedIndicator() {
    const range = speedInRange(1.5, 2.25);
    const fast = speedExactly(3.71, .5);

    return (
        <>
        <SpeedSymbol speed={1.5} description="slow on high sanity while roaming" speedingUp />
        <SpeedSymbol speed={2.25} description="fast on low sanity while roaming" speedingUp />
        <SpeedSymbol speed={3.71} description="really fast when in line-of-sight" />
        <SpeedIndicator speed={speedOr(range, fast)} />
        </>
    );
}