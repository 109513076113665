export type GhostType
    = 'Banshee'
    | 'Demon'
    | 'Deogen'
    | 'Goryo'
    | 'Hantu'
    | 'Jinn'
    | 'Mare'
    | 'Mimic'
    | 'Moroi'
    | 'Myling'
    | 'Obake'
    | 'Oni'
    | 'Onryo'
    | 'Phantom'
    | 'Poltergeist'
    | 'Raiju'
    | 'Revenant'
    | 'Shade'
    | 'Spirit'
    | 'Thaye'
    | 'Twins'
    | 'Wraith'
    | 'Yokai'
    | 'Yurei';

export const ALL_GHOST_TYPES: Array<GhostType> = [
    'Banshee',
    'Demon',
    'Deogen',
    'Goryo',
    'Hantu',
    'Jinn',
    'Mare',
    'Mimic',
    'Moroi',
    'Myling',
    'Obake',
    'Oni',
    'Onryo',
    'Phantom',
    'Poltergeist',
    'Raiju',
    'Revenant',
    'Shade',
    'Spirit',
    'Thaye',
    'Twins',
    'Wraith',
    'Yokai',
    'Yurei',
];

export interface Ghost {
    type: GhostType;
    status: boolean | undefined;
}
