import { selectEvidenceResolver, selectGhosts, toggleGhost } from "../redux/EvidenceSlice";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { ALL_GHOST_TYPES, GhostType } from "../types/Ghost";

export default function GhostIndicator({ ghostType, onSelect }: { ghostType: GhostType, onSelect?: () => void }) {
    const dispatch = useAppDispatch();
    const evidenceResolver = useAppSelector(selectEvidenceResolver);
    const selectedStatus = useAppSelector(selectGhosts).find(next => next.type === ghostType)?.status;
    const resolvedStatus = evidenceResolver.resolveGhost(ghostType);
    const allMatchingGhosts = ALL_GHOST_TYPES.filter(next => evidenceResolver.resolveGhost(next));
    const onlyMatchingGhost = allMatchingGhosts.length === 1 && allMatchingGhosts[0] === ghostType && resolvedStatus === true;

    const select = () => {
        if (onSelect) {
            onSelect();
        }
    };

    const toggle = () => {
        dispatch(toggleGhost(ghostType));
    };

    return (
        <span onClick={select} style={{ cursor: 'pointer' }}>
            {selectedStatus === true && <OnState ghostType={ghostType} toggle={toggle} customState />}
            {selectedStatus === false && <OffState ghostType={ghostType} toggle={toggle} customState />}
            {selectedStatus === undefined && resolvedStatus === true && onlyMatchingGhost && <OnState ghostType={ghostType} toggle={toggle} />}
            {selectedStatus === undefined && resolvedStatus === false && <OffState ghostType={ghostType} toggle={toggle} />}
            {selectedStatus === undefined && resolvedStatus === true && !onlyMatchingGhost && <IndeterminedState ghostType={ghostType} toggle={toggle} />}
        </span>
    );
}

function OnState({ ghostType, toggle, customState }: { ghostType: GhostType, toggle: () => void, customState?: boolean }) {
    if (customState) {
        return (
            <span className="on-ghost-state text-bg-dark fw-bold">
                <button type="button" className="btn p-0 pb-1" onClick={toggle}><i className="bi-check-circle-fill text-warning"></i></button> {ghostType}
            </span>
        );
    } else {
        return (
            <span className="on-ghost-state text-bg-dark fw-bold">
                <button type="button" className="btn p-0 pb-1" onClick={toggle}><i className="bi-check-circle-fill text-success"></i></button> {ghostType}
            </span>
        );
    }
}

function OffState({ ghostType, toggle, customState }: { ghostType: GhostType, toggle: () => void, customState?: boolean }) {
    if (customState) {
        return (
            <span className="off-ghost-state text-muted text-decoration-line-through">
                <button type="button" className="btn p-0 pb-1" onClick={toggle}><i className="bi-x-circle text-warning"></i></button> {ghostType}
            </span>
        );
    } else {
        return (
            <span className="off-ghost-state text-muted text-decoration-line-through opacity-25">
                <button type="button" className="btn p-0 pb-1" onClick={toggle}><i className="bi-x-circle"></i></button> {ghostType}
            </span>
        );
    }
}

function IndeterminedState({ ghostType, toggle }: { ghostType: GhostType, toggle: () => void }) {
    return (
        <span className="indetermined-ghost-state">
            <button type="button" className="btn p-0 pb-1" onClick={toggle}><i className="bi-circle"></i></button> {ghostType}
        </span>
    );
}
