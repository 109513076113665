import GhostIndicator from "../ghosts/GhostIndicator"

export default function HuntSanityThresholds() {
    return (
        <section className="card">
            <header className="card-header">
                <h5 className="card-title">Sanity Threshold</h5>
            </header>
            <main className="card-body">
                Most ghosts hunt at &le; <span className="text-warning">50%</span> average sanity. Exceptions are listed below.
                <ul>
                    <li>The <strong>Banshee</strong> only considers the target's sanity.</li>
                </ul>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div>Early Hunt:</div>
                        <ul className="list-unstyled">
                            <li><GhostIndicator ghostType="Demon" />
                                <ul>
                                    <li>at &le; <span className="text-warning">100%</span> using its ability, or</li>
                                    <li>at &le; <span className="text-warning">70%</span> without special ability.</li>
                                </ul>
                            </li>
                            <li><GhostIndicator ghostType="Mare" /> at &le; <span className="text-warning">60%</span> if in dark.</li>
                            <li><GhostIndicator ghostType="Onryo" />
                                <ul>
                                    <li>at &le; <span className="text-warning">100%</span> after blowing out three candles, or</li>
                                    <li>at &le; <span className="text-warning">60%</span> without special ability.</li>
                                </ul>
                            </li>
                            <li><GhostIndicator ghostType="Raiju" /> at &le; <span className="text-warning">65%</span> if electronic equipment is active.</li>
                            <li><GhostIndicator ghostType="Thaye" /> at &le; <span className="text-warning">75%</span> when young.</li>
                            <li><GhostIndicator ghostType="Yokai" /> at &le; <span className="text-warning">80%</span>, if talking near the ghost.</li>
                            <li><GhostIndicator ghostType="Mimic" /> can imitate hunt behavior of other ghosts.</li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div>Late Hunt:</div>
                        <ul className="list-unstyled">
                            <li><GhostIndicator ghostType="Deogen" /> at &le; <span className="text-warning">40%</span>.</li>
                            <li><GhostIndicator ghostType="Mare" /> at &le; <span className="text-warning">40%</span> if in light.</li>
                            <li><GhostIndicator ghostType="Shade" /> at &le; <span className="text-warning">35%</span>, only considers target's sanity.</li>
                            <li><GhostIndicator ghostType="Thaye" /> at &le; <span className="text-warning">15%</span> when old.</li>
                        </ul>
                    </div>
                </div>
            </main>
        </section>
    );
}