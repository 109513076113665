export default function OuijaBoard() {
    return (
        <dl>
            <dt>Where are you?</dt>
            <dd>Gives the current (not favorite) ghost location. Drops sanity by 50%.</dd>

            <dt className="mt-4">Are you here? Are you close?</dt>
            <dd>Tells if the ghost is in the same room. Drops sanity by 20%.</dd>
            
            <dt className="mt-4">Where is the bone?</dt>
            <dd>Gives the bone location. Drops 50% sanity.</dd>

            <dt className="mt-4">Hide and seek.</dt>
            <dd>Counts down from five and starts a hunt. Also breaks the board.</dd>

            <dt className="mt-4">Do you respond to everyone?</dt>
            <dd>Replies if it's an alone ghost. Drops 20% sanity.</dd>

            <dt className="mt-4">How old are you?</dt>
            <dd>Returns the current age of the ghost. If that age changes, it's a Thaye. Drops 5% sanity.</dd>

            <dt className="mt-4">When did you die?</dt>
            <dd>Responds with a random age. Drops 5% sanity.</dd>

            <dt className="mt-4">What is my sanity?</dt>
            <dd>
                Answers with the following levels. Drops 5% sanity.
                <ul>
                    <li>Healthy (&gt; 80%)</li>
                    <li>Good (60% &minus; 80%)</li>
                    <li>Average (40% &minus; 60%)</li>
                    <li>Bad (20% &minus; 40%)</li>
                    <li>Awful (&lt; 20%)</li>
                </ul>
            </dd>

            <dt className="mt-4">How insane am I?</dt>
            <dd>
                Answers with the following levels. Drops 5% sanity.
                <ul>
                    <li>Not very (&gt; 50%)</li>
                    <li>Very (25% &minus; 50%)</li>
                    <li>Insane (&lt; 25%)</li>
                </ul>
            </dd>

            <dt className="mt-4">Am I insane?</dt>
            <dd>
                Answers with the following levels. Drops 5% sanity.
                <ul>
                    <li>No (&gt; 90%)</li>
                    <li>Maybe (20% &minus; 90%)</li>
                    <li>Yes (&lt; 20%)</li>
                </ul>
            </dd>

            <dt className="mt-4">How did you die?</dt>
            <dd>Random response. Drops 5% sanity.</dd>

            <dt className="mt-4">How do you feel?</dt>
            <dd>Random response. Drops 5% sanity.</dd>

            <dt className="mt-4">Why are you here?</dt>
            <dd>Random response. Drops 5% sanity.</dd>

            <dt className="mt-4">Knock-knock</dt>
            <dd>Who's there. Drops 5% sanity.</dd>

            <dt className="mt-4">Marco</dt>
            <dd>Polo. Drops 5% sanity.</dd>
        </dl>
    );
}