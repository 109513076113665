import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Yurei() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Smudging the ghost will send it back to it's ghost room.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    <ul>
                        <li>All players nearby the ghost will lose 15% of their sanity.</li>
                        <li>Any open door in the ghost room may close, producing EMF 2.</li>
                        <li>If the ghost room has the <strong>front door</strong>, the ghost can even close that. But note that if it also has UV on that <strong>front door</strong>, it's a Mimic.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function YureiSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}