import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Phantom() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    <ul>
                        <li>A phantom is invisible on a photo and there is no glitch visible.</li>
                        <li>If a photo is taken <strong>during an event</strong>, then
                            <ul>
                                <li>the ghost will physically disappear,</li>
                                <li>electronic equipment stops malfunctioning,</li>
                                <li>players don't hear the heartbeat anymore, and</li>
                                <li>the ghost sound is still audible until the event is over.</li>
                            </ul>
                            Note that the ghost will continue to be visible when a photo is taken during 
                            a hunt.
                        </li>
                        <li>Can choose a random player and walks to that location (triggering salt and motion detectors), and EMF 2 is triggered at that location.</li>
                        <li>Drains sanity more quickly when in direct line-of-sight during ghost events or hunts.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Is less often visible during a hunt compared to other ghosts.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function PhantomSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}