import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 717, y: 564 },
    { type: 'Switch 1', id: 'switch1', x: 543, y: 417 },
    { type: 'Light 1', id: 'light1', x: 522, y: 560 },
    { type: 'Switch 2', id: 'switch2', x: 730, y: 388 },
    { type: 'Light 2', id: 'light2', x: 688, y: 426 },
    { type: 'Switch 3', id: 'switch3', x: 360, y: 269 },
    { type: 'Light 3', id: 'light3', x: 417, y: 374 },
    { type: 'Tarot Cards', id: 'tarot', x: 356, y: 408 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 741, y: 376 },
    { type: 'Monkey Paw', id: 'monkey', x: 637, y: 243 },
    { type: 'Haunted Mirror', id: 'mirror', x: 565, y: 423 },
    { type: 'Summoning Circle', id: 'circle', x: 684, y: 615 },
    { type: 'Music Box', id: 'musicbox', x: 411, y: 207 },
    { type: 'Ouija Board', id: 'ouija', x: 533, y: 733 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 81, y: 552 },
    { type: 'Light 1', x: 543, y: 417 },
    { type: 'Light 2', x: 730, y: 388 },
    { type: 'Light 3', x: 360, y: 269 },
    { type: 'Switch 1', x: 81, y: 571 },
    { type: 'Switch 2', x: 81, y: 571 },
    { type: 'Switch 3', x: 81, y: 571 },
    { type: 'Tarot Cards', x: 81, y: 589 },
    { type: 'Voodoo Doll', x: 81, y: 609 },
    { type: 'Monkey Paw', x: 81, y: 629 },
    { type: 'Haunted Mirror', x: 81, y: 646 },
    { type: 'Summoning Circle', x: 81, y: 663 },
    { type: 'Music Box', x: 81, y: 681 },
    { type: 'Ouija Board', x: 81, y: 699 },
];

export default function CampWoodwind() {
    return <InteractiveMap filename="maps/Woodwind.webp" width={1000} height={815} items={ITEMS} legend={LEGEND} />;
}