import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 542, y: 508 },
    { type: 'Fusebox', id: 'fusebox2', x: 135, y: 298 },
    { type: 'Fusebox', id: 'fusebox3', x: 389, y: 185 },
    { type: 'Fusebox', id: 'fusebox4', x: 443, y: 121 },
    { type: 'Fusebox', id: 'fusebox5', x: 815, y: 332 },
    { type: 'Switch 1', id: 'switch1', x: 446, y: 467 },
    { type: 'Light 1', id: 'light1a', x: 435, y: 439 },
    { type: 'Light 1', id: 'light1b', x: 340, y: 488 },
    { type: 'Switch 2', id: 'switch2', x: 408, y: 399 },
    { type: 'Light 2', id: 'light2a', x: 255, y: 342 },
    { type: 'Light 2', id: 'light2b', x: 339, y: 361 },
    { type: 'Light 2', id: 'light2c', x: 450, y: 353 },
    { type: 'Light 3', id: 'light3', x: 184, y: 259 },
    { type: 'Switch 4', id: 'switch4', x: 257, y: 218 },
    { type: 'Light 4', id: 'light4a', x: 274, y: 176 },
    { type: 'Light 4', id: 'light4b', x: 346, y: 161 },
    { type: 'Switch 5', id: 'switch5', x: 433, y: 204 },
    { type: 'Light 5', id: 'light5a', x: 480, y: 143 },
    { type: 'Light 5', id: 'light5b', x: 484, y: 223 },
    { type: 'Switch 6', id: 'switch6', x: 615, y: 139 },
    { type: 'Light 6', id: 'light6a', x: 604, y: 179 },
    { type: 'Light 6', id: 'light6b', x: 703, y: 172 },
    { type: 'Key', id: 'key', x: 682, y: 314 },
    { type: 'Tarot Cards', id: 'tarot', x: 297, y: 214 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 161, y: 276 },
    { type: 'Monkey Paw', id: 'monkey', x: 902, y: 153 },
    { type: 'Haunted Mirror', id: 'mirror', x: 285, y: 417 },
    { type: 'Summoning Circle', id: 'circle', x: 741, y: 368 },
    { type: 'Music Box', id: 'musicbox', x: 658, y: 321 },
    { type: 'Ouija Board', id: 'ouija', x: 496, y: 455 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 34, y: 422 },
    { type: 'Light 1', x: 446, y: 467 },
    { type: 'Light 2', x: 408, y: 399 },
    { type: 'Light 4', x: 257, y: 218 },
    { type: 'Light 5', x: 433, y: 204 },
    { type: 'Light 6', x: 615, y: 139 },
    { type: 'Switch 1', x: 34, y: 452 },
    { type: 'Switch 2', x: 34, y: 452 },
    { type: 'Switch 3', x: 34, y: 452 },
    { type: 'Switch 4', x: 34, y: 452 },
    { type: 'Switch 5', x: 34, y: 452 },
    { type: 'Switch 6', x: 34, y: 452 },
    { type: 'Key', x: 34, y: 438 },
    { type: 'Tarot Cards', x: 34, y: 467 },
    { type: 'Voodoo Doll', x: 34, y: 485 },
    { type: 'Monkey Paw', x: 34, y: 502 },
    { type: 'Haunted Mirror', x: 34, y: 517 },
    { type: 'Summoning Circle', x: 34, y: 533 },
    { type: 'Music Box', x: 34, y: 549 },
    { type: 'Ouija Board', x: 34, y: 564 },
];

export default function MapleLodgeCampsite() {
    return <InteractiveMap filename="maps/Maple_Lodge_Campsite_-_Floorplan.webp" width={1000} height={663} items={ITEMS} legend={LEGEND} />;
}
