import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly, speedOr } from "../SpeedIndicator";
import SpeedSymbol from "../SpeedSymbol";

export default function Raiju() {
    return (
        <div>
            <div>The Raiju siphons energy from electronic equipment to get more powerful and faster during a hunt.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    During an event or hunt the Raiju will interfere with electronics in a 15 m radius, instead of the 
                    usual 10 m radius.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <RaijuSanityIndicator /> or lower, if active electronic equipment is nearby, otherwise <SanityIndicator sanity={50} />.</li>
                        <li><RaijuSpeedIndicator /> Moves significantly faster during a hunt, if at least one active electronic equipment is nearby.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function RaijuSanityIndicator() {
    return <SanityIndicator sanity={60} />;
}

export function RaijuSpeedIndicator() {
    return (
        <>
        <SpeedSymbol speed={2.5} description="fast when near electronic equipment" speedingUp />
        <SpeedIndicator speed={speedOr(speedExactly(1.7), speedExactly(2.5))} />
        </>
    );
}