import { ALL_PRIMARY_EVIDENCE_TYPES, Evidence, PrimaryEvidenceType, SecondaryEvidenceType, primaryEvidenceTypes } from "./Evidence";
import { EvidenceResolver } from "./EvidenceResolver";
import { ALL_GHOST_TYPES, GhostType } from "./Ghost";
import { SecondaryEvidenceResolver } from "./SecondaryEvidenceResolver";

export class ThreeEvidenceResolver implements EvidenceResolver {

    private readonly evidences: Array<Evidence>;
    private readonly ghosts: Map<GhostType, boolean>;
    private readonly secondaryEvidenceResolver: SecondaryEvidenceResolver;

    constructor(evidences: Array<Evidence>) {
        const ghosts = new Map<GhostType, boolean>(ALL_GHOST_TYPES.map(ghostType => ([ghostType, true])));
        const resolvers = new Map<PrimaryEvidenceType, (ghostType: GhostType) => boolean | undefined>(ALL_PRIMARY_EVIDENCE_TYPES.map(evidenceType => ([evidenceType, primaryEvidence(evidenceType, evidences)])));
        
        for (const evidenceType of ALL_PRIMARY_EVIDENCE_TYPES) {
            const resolver = resolvers.get(evidenceType);
            
            for (const ghostType of ALL_GHOST_TYPES) {
                const status = resolver ? resolver(ghostType) : undefined;
                if (status === false) {
                    // Evidence ruled out or doesn't match.
                    ghosts.set(ghostType, false);
                }
            }
        }
        
        this.evidences = evidences;
        this.ghosts = ghosts;
        this.secondaryEvidenceResolver = new SecondaryEvidenceResolver(evidences);
    }

    resolvePrimaryEvidence(evidenceType: PrimaryEvidenceType): boolean | undefined {
        const evidence = this.evidences.find(next => next.type === evidenceType);
        return evidence?.status;
    }

    resolveEvidence(evidenceType: PrimaryEvidenceType | SecondaryEvidenceType): boolean | undefined {
        if (ALL_PRIMARY_EVIDENCE_TYPES.includes(evidenceType as any)) {
            return this.resolvePrimaryEvidence(evidenceType as PrimaryEvidenceType);
        } else {
            return this.secondaryEvidenceResolver.resolveEvidence(evidenceType);
        }
    }

    resolveGhost(ghostType: GhostType): boolean {
        const status = this.ghosts.get(ghostType) ?? false;
        if (status === false) {
            return false;
        }

        const secondaryStatus = this.secondaryEvidenceResolver.resolveGhost(ghostType);
        if (secondaryStatus === false) {
            return false;
        }

        return true;
    }

}

function primaryEvidence(evidenceType: PrimaryEvidenceType, evidences: Array<Evidence>): (ghostType: GhostType) => boolean | undefined {
    const matchingGhostTypes = new Set(ALL_GHOST_TYPES.filter(next => primaryEvidenceTypes(next).includes(evidenceType)));
    const status = evidences.find(next => next.type === evidenceType)?.status;

    if (status === true) {
        // Evidence was found -> ghost must have that evidence.
        return (ghostType) => {
            if (ghostType === 'Mimic' && evidenceType === 'Ghost Orbs') {
                return true; // Special case: Ghost Orbs aren't an evidence, but an ability for the Mimic.
            } else {
                return matchingGhostTypes.has(ghostType);
            }
        };
    }

    else if (status === false) {
        return (ghostType) => {
            if (ghostType === 'Mimic' && evidenceType === 'Ghost Orbs') {
                return false; // Special case: Mimic always has ghost orbs, which isn't an evidence though.
            } else if (matchingGhostTypes.has(ghostType)) {
                // Evidence not found, ghost requires this evidence -> ghost doesn't match.
                return false;
            } else {
                // Evidence not found, ghost doesn't require this evidence -> inconclusive.
                return undefined;
            }
        };
    }

    else {
        // Evidence is neither found, nor ruled out.
        return (ghostType) => undefined;
    }
}
