import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Goryo() {
    return (
        <div>
            <div>A Goryo will only appear on D.O.T.S. when viewed on video, and remains within the vicinity of the ghost room.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /> <sup>1, 2</sup></li>
                    </ul>
                    <div>
                        <sup>1</sup> When playing with 1 or 2 evidence, D.O.T.S. is guaranteed to be there due 
                        to the ghost's ability.
                    </div>
                    <div>
                        <sup>2</sup> When D.O.T.S. is visible from within the room directly, we can rule out 
                        Goryo.
                    </div>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Roams only short distances around the ghost room and can't change the ghost room.</li>
                        <li>D.O.T.S. will only be visible when viewed on video when no people are nearby.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    Shows <strong>D.O.T.S.</strong> only if nobody is in the room, and it can only be seen 
                    on video. So either somebody is outside the ghost room filming it, or a camera is placed 
                    in the room facing D.O.T.S. and is viewed from the truck.
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function GoryoSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />;
}