import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedInRange } from "../SpeedIndicator";
import SpeedSymbol from "../SpeedSymbol";

export default function Hantu() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /> <sup>1</sup></li>
                    </ul>
                    <div>
                        <sup>1</sup> When playing with 1 or 2 evidence, freezing temperatures is guaranteed to be there due 
                        to the ghost's ability.
                    </div>
                    <div className="mt-2">
                        <i className="bi-exclamation-triangle"></i> May be a <em>Mimic</em>, check if you get <span className="me-1">a</span>
                        <EvidendeIndicator evidenceType="Spirit Box" /> response. If so, it's a Mimic instead.
                    </div>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Can't <strong>turn on</strong> the fuse box.</li>
                        <li>Twice as likely to <strong>turn off</strong> the fuse box.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    Moves faster in colder environments.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Does not speed up if it sees the target.</li>
                        <li><HantuSpeedIndicator /> Moves very fast in cold rooms.</li>
                        <li>Ghost model has freezing breath during a hunt when the ghost becomes visible and the breaker is off.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function HantuSpeedIndicator() {
    return (
        <>
        <SpeedSymbol speed={2.7} description="fast in cold environments" keepingSpeed />
        <SpeedIndicator speed={speedInRange(1.4, 2.7, .5)} />
        </>
    );
}