import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 280, y: 395 },
    { type: 'Fusebox', id: 'fusebox2', x: 629, y: 186 },
    { type: 'Key', id: 'key', x: 200, y: 430 },
    { type: 'Tarot Cards', id: 'tarot', x: 243, y: 245 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 335, y: 397 },
    { type: 'Monkey Paw', id: 'monkey', x: 340, y: 300 },
    { type: 'Haunted Mirror', id: 'mirror', x: 158, y: 346 },
    { type: 'Summoning Circle', id: 'circle', x: 631, y: 218 },
    { type: 'Music Box', id: 'musicbox', x: 131, y: 377 },
    { type: 'Ouija Board', id: 'ouija', x: 651, y: 316 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 370, y: 366 },
    { type: 'Key', x: 370, y: 386 },
    { type: 'Tarot Cards', x: 370, y: 405 },
    { type: 'Voodoo Doll', x: 370, y: 430 },
    { type: 'Monkey Paw', x: 370, y: 452 },
    { type: 'Haunted Mirror', x: 370, y: 472 },
    { type: 'Summoning Circle', x: 370, y: 492 },
    { type: 'Music Box', x: 370, y: 513 },
    { type: 'Ouija Board', x: 370, y: 535 },
];

export default function TanglewoodDrive() {
    return <InteractiveMap filename="maps/6_Tanglewood_Drive_-_Floorplan.webp" width={786} height={676} items={ITEMS} legend={LEGEND} />;
}
