export default function EmfMeter() {
    return (
        <div>
            EMF Levels
            <ol>
                <li>default state</li>
                <li>touched an object</li>
                <li>throwed an object</li>
                <li>manifestation</li>
                <li>evidence (25% chance)</li>
            </ol>
        </div>
    );
}