import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Shade() {
    return (
        <div>
            <div>A Shade is a very shy ghost and has less activity the more players are nearby.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Less likely for interactions in general than other ghosts.</li>
                        <li>At 100% sanity it has a 0% chance for a ghost event. The chance increases when sanity decreases. At 50% sanity it has a normal chance for events.</li>
                        <li>Has a higher chance of appearing as a shadow in a ghost event.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Inability">
                    Can't do interactions when somebody is in the same room, except 
                    <ul>
                        <li>EMF 2,</li>
                        <li>ghost writing,</li>
                        <li>ghost events</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <ShadeSanityIndicator /> or lower, but only considers the target's sanity.</li>
                        <li>Can't initiate a hunt when somebody is in the same room, regardless of sanity.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function ShadeSanityIndicator() {
    return <SanityIndicator sanity={35} />;
}

export function ShadeSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}