import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 448, y: 566 },
    { type: 'Fusebox', id: 'fusebox2', x: 464, y: 362 },
    { type: 'Fusebox', id: 'fusebox3', x: 610, y: 420 },
    { type: 'Tarot Cards', id: 'tarot', x: 551, y: 408 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 551, y: 408 },
    { type: 'Monkey Paw', id: 'monkey', x: 551, y: 408 },
    { type: 'Haunted Mirror', id: 'mirror', x: 551, y: 408 },
    { type: 'Summoning Circle', id: 'circle', x: 551, y: 408 },
    { type: 'Music Box', id: 'musicbox', x: 551, y: 408 },
    { type: 'Ouija Board', id: 'ouija', x: 551, y: 408 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 754, y: 296 },
    { type: 'Tarot Cards', x: 754, y: 308 },
    { type: 'Voodoo Doll', x: 754, y: 320 },
    { type: 'Monkey Paw', x: 754, y: 332 },
    { type: 'Haunted Mirror', x: 754, y: 355 },
    { type: 'Summoning Circle', x: 754, y: 366 },
    { type: 'Music Box', x: 754, y: 380 },
    { type: 'Ouija Board', x: 754, y: 391 },
];

export default function SunnyMeadowsCourtyard() {
    return <InteractiveMap filename="maps/Sunny_Meadows_Mental_Institution_-_Restricted_Map-Courtyard_Floorplan.webp" width={1000} height={688} items={ITEMS} legend={LEGEND} />;
}
