import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./redux/Hooks";
import { GhostType } from "./types/Ghost";
import { changeTimerOffset, resetSpeedAndTimer, selectActiveTimer, selectHuntTimer, selectSmudgeTimer, toggleTimer } from "./redux/EvidenceSlice";

import GhostList from "./ghosts/GhostList";
import PrimaryEvidenceList from "./evidence/PrimaryEvidenceList";
import SecondaryEvidenceList from "./evidence/SecondaryEvidenceList";
import GhostDetails from "./ghosts/details";
import GadgetList from "./stuff/GadgetList";
import HuntSanityThresholds from "./hunts/HuntSanityThresholds";
import Maps from "./maps/Maps";
import CursedItemList from "./stuff/CursedItemList";
import Footsteps from "./evidence/Footsteps";
import EvidenceOptions from "./evidence/EvidenceOptions";
import { MapName } from "./types/MapName";

type NavItemType = 'Ghosts' | 'Stuff' | 'Hunt' | 'Maps';

export default function Content() {
    const dispatch = useAppDispatch();
    const smudgeTimer = useAppSelector(selectSmudgeTimer);
    const huntTimer = useAppSelector(selectHuntTimer);
    const activeTimer = useAppSelector(selectActiveTimer);
    const [navItem, setNavItem] = useState<NavItemType>('Ghosts');
    const [selectedGhostType, setSelectedGhostType] = useState<GhostType | undefined>();
    const [selectedMapName, setSelectedMapName] = useState<MapName | ''>('');

    const toggleSelectedGhostType = (ghostType: GhostType) => {
        if (ghostType === selectedGhostType) {
            setSelectedGhostType(undefined);
        } else {
            setSelectedGhostType(ghostType);
        }
    };

    const handleKey: EventListener = useCallback((event) => {
        if ('key' in event) {
            const key = `${event.key}`.toLowerCase();
            if (key === 'backspace') {
                dispatch(resetSpeedAndTimer());
            } else if (key === 'tab') {
                dispatch(toggleTimer());
            } else if (key === '+' && (!!smudgeTimer || !!huntTimer)) {
                dispatch(changeTimerOffset(1));
            } else if (key === '-' && (!!smudgeTimer || !!huntTimer)) {
                dispatch(changeTimerOffset(-1));
            }
        }
    }, [dispatch, huntTimer, smudgeTimer]);

    useEffect(() => {
        window.addEventListener('keydown', handleKey);
        return () => window.removeEventListener('keydown', handleKey);
    }, [handleKey]);

    return (
        <div className="row">
            <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                <PrimaryEvidenceList />
                <SecondaryEvidenceList />
                <EvidenceOptions />
            </div>
            <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                <ul className="nav nav-tabs">
                    <NavItem type="Ghosts" selected={navItem === 'Ghosts'} onSelect={setNavItem} />
                    <NavItem type="Stuff" selected={navItem === 'Stuff'} onSelect={setNavItem} />
                    <NavItem type="Hunt" selected={navItem === 'Hunt'} onSelect={setNavItem} />
                    <NavItem type="Maps" selected={navItem === 'Maps'} onSelect={setNavItem} />
                </ul>
                {navItem === 'Ghosts' && <Ghosts selectedGhostType={selectedGhostType} toggleSelectedGhostType={toggleSelectedGhostType} />}
                {navItem === 'Stuff' && <div className="d-flex justify-content-between align-items-start"><GadgetList /><CursedItemList /></div>}
                {navItem === 'Hunt' && <HuntSanityThresholds />}
                {navItem === 'Maps' && <Maps mapName={selectedMapName} onChange={setSelectedMapName} />}

                <div className="text-muted fw-lighter m-2 mt-4">
                    <span className="me-4"><kbd title="Enter"><i className="bi-arrow-return-left"></i></kbd> Record a footstep</span>
                    <span className="me-4"><kbd title="Escape"><i className="bi-backspace"></i></kbd> Clear footsteps and timer</span>
                    <span className="me-4"><kbd><small>S</small></kbd> {!!smudgeTimer ? "Start" : "Stop"} <u>s</u>mudge timer</span>
                    <span className="me-4"><kbd><small>H</small></kbd> {!!huntTimer ? "Start" : "Stop"} <u>h</u>unt timer</span>
                    {!!activeTimer &&
                        <div>
                            {!!smudgeTimer && !!huntTimer && <span className="me-4"><kbd><i className="bi-indent"></i></kbd> Toggle active timer</span>}
                            <span className="me-4"><kbd><i className="bi-plus-lg"></i></kbd> Add a second to the {activeTimer}</span>
                            <span className="me-4"><kbd><i className="bi-dash-lg"></i></kbd> Subtract a second from the {activeTimer}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

function NavItem({ type, selected, onSelect }: { type: NavItemType, selected: boolean, onSelect: (type: NavItemType) => void }) {
    return (
        <li className="nav-item">
            <button type="button" className={selected ? "active nav-link" : "nav-link"} onClick={() => onSelect(type)}>{type}</button>
        </li>
    );
}

function Ghosts({ selectedGhostType, toggleSelectedGhostType }: { selectedGhostType: GhostType | undefined, toggleSelectedGhostType: (value: GhostType) => void }) {
    return (
        <div>
            <GhostList selectedGhostType={selectedGhostType} onSelect={toggleSelectedGhostType} />
            <Footsteps />
            {!!selectedGhostType && <GhostDetails ghostType={selectedGhostType} />}
        </div>
    );
}