export interface SessionStorageRepresentable<T> {
    read: () => T | null;
    write: (value: T) => void;
    clear: () => void;
}

export function sessionStringState(key: string): SessionStorageRepresentable<string> {
    const read = () => sessionStorage.getItem(key);

    const write = (value: string) => {
        if (!!value?.length) {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.removeItem(key);
        }
    };

    const clear = () => sessionStorage.removeItem(key);

    return { read, write, clear };
}

export function sessionNumberState(key: string): SessionStorageRepresentable<number> {
    const read = () => {
        const value = sessionStorage.getItem(key);
        if (!!value?.length) {
            return parseFloat(value);
        } else {
            return null;
        }
    };

    const write = (value: number) => {
        sessionStorage.setItem(key, `${value}`);
    };

    const clear = () => sessionStorage.removeItem(key);

    return { read, write, clear };
}

export function sessionJsonState<T>(key: string, parse?: (json: string) => T | null, format?: (value: T) => string): SessionStorageRepresentable<T> {
    const read = () => {
        const json = sessionStorage.getItem(key);
        if (!json?.length) {
            return null;
        }

        if (parse) {
            return parse(json);
        }

        const value = JSON.parse(json);
        if (value === null || value === undefined) {
            return null;
        } else {
            return value;
        }
    };

    const write = (value: T) => {
        if (format) {
            const json = format(value);
            if (json?.length) {
                sessionStorage.setItem(key, json);
            } else {
                sessionStorage.removeItem(key);
            }
        } else {
            const json = JSON.stringify(value);
            sessionStorage.setItem(key, json);
        }
    };

    const clear = () => sessionStorage.removeItem(key);

    return { read, write, clear };
}

export function sessionArrayState<T>(key: string, parse?: (json: string) => T | null, format?: (value: T) => string): SessionStorageRepresentable<Array<T>> {
    const read = () => {
        const json = sessionStorage.getItem(key);
        if (!json?.length) {
            return null;
        }

        if (parse) {
            return json
                .split('::')
                .map(parse)
                .filter(value => value !== null)
                .map(value => value as T);
        }

        const value = JSON.parse(json);
        if (value === null || value === undefined) {
            return [];
        } else {
            return value as T[];
        }
    };

    const write = (value: T[]) => {
        if (!value?.length) {
            sessionStorage.removeItem(key);
        } else if (format) {
            const json = value.map(format).join('::');
            if (json?.length) {
                sessionStorage.setItem(key, json);
            } else {
                sessionStorage.removeItem(key);
            }
        } else {
            const json = JSON.stringify(value);
            sessionStorage.setItem(key, json);
        }
    };

    const clear = () => sessionStorage.removeItem(key);

    return { read, write, clear };
}
