import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly, speedOr } from "../SpeedIndicator";

export default function Twins() {
    return (
        <div>
            <div>They act like two ghosts.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>You might a 2nd interaction immediately after a 1st one.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    When The Twins uses its ability to cause in interaction, it will cause a second interaction
                    somewhere else. These interactions have a 25% chance of producing EMF 5 instead of EMF 2/3.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>There is a 50% chance that the hunt starts not where the ghost actually is, but where it used its ability last.</li>
                        <li>The crucifix will be consued at the actual ghost location before it might "decide" to start the hunt at the other location.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function TwinsSpeedIndicator() {
    const speedAtPhysicalLocation = speedExactly(1.53);
    const speedAtRangedLocation = speedExactly(1.87);
    
    return <SpeedIndicator speed={speedOr(speedAtPhysicalLocation, speedAtRangedLocation)} />;
}