import { EvidenceCount } from "./Evidence";

export interface GameOptions {
    evidenceCount: EvidenceCount;
    speedFactor: number;
}

export function defaultGameOptions(): GameOptions {
    return {
        evidenceCount: 3,
        speedFactor: 1,
    };
}
