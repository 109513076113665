import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 707, y: 711 },
    { type: 'Fusebox', id: 'fusebox2', x: 694, y: 407 },
    { type: 'Tarot Cards', id: 'tarot', x: 815, y: 220 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 815, y: 220 },
    { type: 'Monkey Paw', id: 'monkey', x: 815, y: 220 },
    { type: 'Haunted Mirror', id: 'mirror', x: 815, y: 220 },
    { type: 'Summoning Circle', id: 'circle', x: 815, y: 220 },
    { type: 'Music Box', id: 'musicbox', x: 815, y: 220 },
    { type: 'Ouija Board', id: 'ouija', x: 815, y: 220 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 177, y: 211 },
    { type: 'Tarot Cards', x: 177, y: 225 },
    { type: 'Voodoo Doll', x: 177, y: 240 },
    { type: 'Monkey Paw', x: 177, y: 253 },
    { type: 'Haunted Mirror', x: 177, y: 280 },
    { type: 'Summoning Circle', x: 177, y: 294 },
    { type: 'Music Box', x: 177, y: 310 },
    { type: 'Ouija Board', x: 177, y: 322 },
];

export default function SunnyMeadowsHospitalWing() {
    return <InteractiveMap filename="maps/Sunny_Meadows_Restricted_Map_-_Hospital_Wing_Floorplan.webp" width={1000} height={867} items={ITEMS} legend={LEGEND} />;
}
