export interface StopWatch {
    start: number;
    end?: number;
    offset?: number;
}

export function calculateTimeInSeconds(stopWatch: StopWatch, adjustUsingOffset: boolean = true): number {
    const now = new Date().getTime();
    const end = stopWatch.end ?? now;
    
    let duration = (end - stopWatch.start) * 0.001; // seconds
    if (adjustUsingOffset) {
        const offset = stopWatch.offset ?? 0; // seconds
        duration += offset;
    }

    return Math.round(duration);
}

export function formatStopWatch(stopWatch: StopWatch, adjustUsingOffset: boolean = true): string {
    let time = calculateTimeInSeconds(stopWatch, adjustUsingOffset);

    const hours = Math.floor(time / 3600);
    time -= hours * 3600;

    const minutes = Math.floor(time / 60);
    time -= minutes * 60;

    const seconds = Math.floor(time);

    const components: string[] = [];
    if (hours > 0) {
        components.push(`${hours}`);
    }

    components.push(minutes < 10 ? `0${minutes}` : `${minutes}`);
    components.push(seconds < 10 ? `0${seconds}` : `${seconds}`);

    return components.join(':');
}
