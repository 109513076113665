import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly, speedOr } from "../SpeedIndicator";
import SpeedSymbol from "../SpeedSymbol";

export default function Deogen() {
    return (
        <div>
            <div>A Deogen can see players even if they hide.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /> <sup>1</sup></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /></li>
                    </ul>
                    <div>
                        <sup>1</sup> When playing with 1 or 2 evidence, spirit box is guaranteed to be there due 
                        to the ghost's ability.
                    </div>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    33% chance of producing a <strong>heavy bull-like breathing</strong> instead of a 
                    normal response, when talking to it using the <strong>spirit box</strong>, if the 
                    ghost is within 1m range.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <DeogenSanityIndicator /> or lower.</li>
                        <li>Can see all the players through walls etc, there is no hiding.</li>
                        <li><DeogenSpeedIndicator /> Is very fast at first and slows down when it's near the target.</li>
                        <li>Is visible for longer than other ghosts during a hunt.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function DeogenSanityIndicator() {
    return <SanityIndicator sanity={40} />
}

export function DeogenSpeedIndicator() {
    const slow = speedExactly(0.4);
    const fast = speedExactly(3.0, .5);

    return (
        <>
        <SpeedSymbol speed={3.0} description="fast when far away" slowingDown />
        <SpeedSymbol speed={0.4} description="show when near a player" keepingSpeed />
        <SpeedIndicator speed={speedOr(slow, fast)} />
        </>
    );
}