import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Yokai() {
    return (
        <div>
            <div>A Yokai gets more aggressive when hearing voices.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Talking near a Yokai will increase it's rate of activity.</li>
                        <li>Using the <strong>music box</strong> must be done near the Yokai to trigger the event and cursed hunt.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <YokaiSanityIndicator /> or lower, if a player is talking near the Yokai. Otherwise it's <SanityIndicator sanity={50} />.</li>
                        <li>The Yokai can only hear or sense a player within 2.5 m (not including line-of-sight).</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function YokaiSanityIndicator() {
    return <SanityIndicator sanity={80} />;
}

export function YokaiSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}