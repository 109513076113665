export default function SpeedSymbol({ speed, description, speedingUp, slowingDown, keepingSpeed }: { speed: number, description?: string, speedingUp?: boolean, slowingDown?: boolean, keepingSpeed?: boolean }) {
    const category = getCategory(speed);
    const badge = category === 'fast' ? 'badge p-0 ps-1 text-warning' : (category === 'slow' ? 'badge p-0 ps-1 text-info' : 'badge p-0 ps-1');

    return (
        <span className={badge} title={description}>
            <i className="bi-person-walking"></i>
            <FormattedSpeed speed={speed} />
            {!!speedingUp && <i className="bi-arrow-up-right" title="speeding up"></i>}
            {!!slowingDown && <i className="bi-arrow-down-right" title="slowing down"></i>}
            {!!keepingSpeed && <i className="bi-arrow-right" title="keeping speed"></i>}
        </span>
    );
}

type Category = 'slow' | 'normal' | 'fast';

function getCategory(speed: number): Category {
    if (speed < 1.7) {
        return 'slow';
    } else if (speed > 1.7) {
        return 'fast';
    } else {
        return 'normal';
    }
}

function FormattedSpeed({ speed }: { speed: number }) {
    const str = speed.toFixed(1);
    const category = getCategory(speed);

    if (category === 'normal') {
        return null;
    }

    return <span title={`${str} m/s`}>{str}</span>
}
