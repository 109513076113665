import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly, speedOr } from "../SpeedIndicator";
import SpeedSymbol from "../SpeedSymbol";

export default function Jinn() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Can't <strong>turn off</strong> the fuse box</li>
                        <li>Whether the lights are turned on will <em>not</em> affect the Jinn's abilities.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    When the fuse box is on and the ghost uses it's ability, it will drain 25% of sanity 
                    of all players in range. An EMF 2 or EMF 5 will be generated at the fuse box.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li><JinnSpeedIndicator /> Is faster when the fuse box is on and the Jinn is in line-of-sight with it's target and more than 3 m away.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function JinnSpeedIndicator() {
    const normal = speedExactly(1.7);
    const fast = speedExactly(2.5);

    return (
        <>
        <SpeedSymbol speed={2.5} description="fast when breaker is on" slowingDown />
        <SpeedIndicator speed={speedOr(normal, fast)} />
        </>
    );
}