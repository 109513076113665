export default function SpiritBox() {
    return (
        <div>
            <div>
                <strong>Deogen:</strong> has a 33% chance of responding with heavy breathing instead 
                of a normal response.
            </div>
            <div>
                <strong>Moroi:</strong> will curse the player using the spirit box.
            </div>
            <hr />
            <div>
                Questions:
                <ul>
                    <li>Are you friendly?</li>
                    <li>Where are you?</li>
                    <li>Are you here?</li>
                    <li>Are you close?</li>
                    <li>Give us a sign.</li>
                    <li>Speak to us.</li>
                    <li>Can you talk?</li>
                    <li>Show yourself.</li>
                    <li>How old are you?</li>
                    <li>Are you old?</li>
                    <li>Are you young?</li>
                    <li>Why are you here?</li>
                    <li>What do you want?</li>
                    <li>Do you want to hurt us?</li>
                    <li>Should we leave?</li>
                </ul>
            </div>
        </div>
    );
}