import { GhostType } from "../../types/Ghost";

import Banshee from "./Banshee";
import Demon from "./Demon";
import Deogen from "./Deogen";
import Goryo from "./Goryo";
import Hantu from "./Hantu";
import Jinn from "./Jinn";
import Mare from "./Mare";
import Mimic from "./Mimic";
import Moroi from "./Moroi";
import Myling from "./Myling";
import Obake from "./Obake";
import Oni from "./Oni";
import Onryo from "./Onryo";
import Phantom from "./Phantom";
import Poltergeist from "./Poltergeist";
import Raiju from "./Raiju";
import Revenant from "./Revenant";
import Shade from "./Shade";
import Spirit from "./Spirit";
import Thaye from "./Thaye";
import Twins from "./Twins";
import Wraith from "./Wraith";
import Yokai from "./Yokai";
import Yurei from "./Yurei";

export default function GhostDetails({ ghostType }: { ghostType: GhostType }) {
    return (
        <section className="card m-1">
            <header className="card-header">
                <h5 className="card-title">{ghostType}</h5>
            </header>
            <main className="card-body">
                <ContentView ghostType={ghostType} />
            </main>
        </section>
    );
}

function ContentView({ ghostType }: { ghostType: GhostType }) {
    switch (ghostType) {
        case 'Banshee':
            return <Banshee />;
        case 'Demon':
            return <Demon />;
        case 'Deogen':
            return <Deogen />;
        case 'Goryo':
            return <Goryo />;
        case 'Hantu':
            return <Hantu />;
        case 'Jinn':
            return <Jinn />;
        case 'Mare':
            return <Mare />;
        case 'Moroi':
            return <Moroi />;
        case 'Myling':
            return <Myling />;
        case 'Obake':
            return <Obake />;
        case 'Oni':
            return <Oni />;
        case 'Onryo':
            return <Onryo />;
        case 'Phantom':
            return <Phantom />;
        case 'Poltergeist':
            return <Poltergeist />;
        case 'Raiju':
            return <Raiju />;
        case 'Revenant':
            return <Revenant />;
        case 'Shade':
            return <Shade />;
        case 'Spirit':
            return <Spirit />;
        case 'Thaye':
            return <Thaye />;
        case 'Mimic':
            return <Mimic />;
        case 'Twins':
            return <Twins />;
        case 'Wraith':
            return <Wraith />;
        case 'Yokai':
            return <Yokai />;
        case 'Yurei':
            return <Yurei />;
    }

    return null;
}