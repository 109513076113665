import { useState } from "react";
import MonkeyPaw from "./MonkeyPaw";
import OuijaBoard from "./OuijyBoard";
import TarotCards from "./TarotCards";
import SummoningCircle from "./SummoningCircle";

type CursedItemType = 'Monkey Paw' | 'Ouija Board' | 'Tarot Cards' | 'Summoning Circle';

export default function CursedItemList() {
    const [cursedItemType, setCursedItemType] = useState<CursedItemType | undefined>();

    return (
        <section className="card m-1 w-100">
            <header className="card-header">
                <h5 className="card-title">Cursed Items</h5>
            </header>
            <main className="card-body p-0">
                <div className="accordion accordion-flush">
                    <AccordionItem item="Monkey Paw" selectedItem={cursedItemType} setSelectedItem={setCursedItemType}>
                        <MonkeyPaw />
                    </AccordionItem>
                    <AccordionItem item="Ouija Board" selectedItem={cursedItemType} setSelectedItem={setCursedItemType}>
                        <OuijaBoard />
                    </AccordionItem>
                    <AccordionItem item="Tarot Cards" selectedItem={cursedItemType} setSelectedItem={setCursedItemType}>
                        <TarotCards />
                    </AccordionItem>
                    <AccordionItem item="Summoning Circle" selectedItem={cursedItemType} setSelectedItem={setCursedItemType}>
                        <SummoningCircle />
                    </AccordionItem>
                </div>
            </main>
        </section>
    );
}

function AccordionItem({ item, selectedItem, setSelectedItem, children }: { item: CursedItemType, selectedItem: CursedItemType | undefined, setSelectedItem: (itemType: CursedItemType | undefined) => void, children: JSX.Element }) {
    const expanded = item === selectedItem;
    const toggle = () => setSelectedItem(expanded ? undefined : item);

    return (
        <section className="accordion-item">
            <header className="accordion-header">
                <button type="button" className={expanded ? "accordion-button" : "accordion-button collapsed"} onClick={toggle}>
                    {item}
                </button>
            </header>
            <main className={expanded ? "accordion-collapse" : "accordion-collapse collapse"}>
                <div className="accordion-body">
                    {children}
                </div>
            </main>
        </section>
    );
}