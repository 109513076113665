import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Spirit() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Smudging a Spirit will affect it for 180s instead of 90s.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function SpiritSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}