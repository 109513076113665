import HuntTimer from "../evidence/HuntTimer";
import SmudgeTimer from "../evidence/SmudgeTimer";
import { selectEvidenceResolver } from "../redux/EvidenceSlice";
import { useAppSelector } from "../redux/Hooks";
import { ALL_GHOST_TYPES, GhostType } from "../types/Ghost";

import GhostIndicator from "./GhostIndicator";
import { BansheeSpeedIndicator } from "./details/Banshee";
import { DemonSanityIndicator, DemonSpeedIndicator } from "./details/Demon";
import { DeogenSpeedIndicator, DeogenSanityIndicator } from "./details/Deogen";
import { GoryoSpeedIndicator } from "./details/Goryo";
import { HantuSpeedIndicator } from "./details/Hantu";
import { JinnSpeedIndicator } from "./details/Jinn";
import { MareSanityIndicator, MareSpeedIndicator } from "./details/Mare";
import { MimicSpeedIndicator } from "./details/Mimic";
import { MoroiSpeedIndicator } from "./details/Moroi";
import { MylingSpeedIndicator } from "./details/Myling";
import { ObakeSpeedIndicator } from "./details/Obake";
import { OniSpeedIndicator } from "./details/Oni";
import { OnryoSanityIndicator, OnryoSpeedIndicator } from "./details/Onryo";
import { PhantomSpeedIndicator } from "./details/Phantom";
import { PoltergeistSpeedIndicator } from "./details/Poltergeist";
import { RaijuSanityIndicator, RaijuSpeedIndicator } from "./details/Raiju";
import { RevenantSpeedIndicator } from "./details/Revenant";
import { ShadeSanityIndicator, ShadeSpeedIndicator } from "./details/Shade";
import { SpiritSpeedIndicator } from "./details/Spirit";
import { ThayeSanityIndicator, ThayeSpeedIndicator } from "./details/Thaye";
import { TwinsSpeedIndicator } from "./details/Twins";
import { WraithSpeedIndicator } from "./details/Wraith";
import { YokaiSanityIndicator, YokaiSpeedIndicator } from "./details/Yokai";
import { YureiSpeedIndicator } from "./details/Yurei";

export default function GhostList({ selectedGhostType, onSelect }: { selectedGhostType: GhostType | undefined, onSelect: (ghostType: GhostType) => void }) {
    const evidenceResolver = useAppSelector(selectEvidenceResolver);

    const select = (ghostType: GhostType) => () => onSelect(ghostType);

    const ghosts = ALL_GHOST_TYPES.map(ghostType => {
        const status = evidenceResolver.resolveGhost(ghostType);

        return (
            <div key={ghostType} className={selectedGhostType === ghostType ? "rounded border border-primary p-1" : "rounded border border-none p-1"}>
                <GhostIndicator ghostType={ghostType} onSelect={select(ghostType)} />
                {status !== false && <SanityIndicator ghostType={ghostType} />}
                {status !== false && <SpeedIndicator ghostType={ghostType} />}
            </div>
        );
    });

    return (
        <section className="card m-1">
            <header className="card-header">
                <h5 className="card-title">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <span>Ghosts</span>
                        <div>
                            <SmudgeTimer />
                            <HuntTimer />
                        </div>
                    </div>
                </h5>
            </header>
            <main className="p-2 ghost-list">
                {ghosts}
            </main>
        </section>
    );
}

function SpeedIndicator({ ghostType }: { ghostType: GhostType }) {
    switch (ghostType) {
        case 'Banshee':
            return <BansheeSpeedIndicator />;
        case 'Demon':
            return <DemonSpeedIndicator />;
        case 'Deogen':
            return <DeogenSpeedIndicator />;
        case 'Goryo':
            return <GoryoSpeedIndicator />;
        case 'Hantu':
            return <HantuSpeedIndicator />;
        case 'Jinn':
            return <JinnSpeedIndicator />;
        case 'Mare':
            return <MareSpeedIndicator />;
        case 'Mimic':
            return <MimicSpeedIndicator />;
        case 'Moroi':
            return <MoroiSpeedIndicator />;
        case 'Myling':
            return <MylingSpeedIndicator />;
        case 'Obake':
            return <ObakeSpeedIndicator />;
        case 'Oni':
            return <OniSpeedIndicator />;
        case 'Onryo':
            return <OnryoSpeedIndicator />;
        case 'Phantom':
            return <PhantomSpeedIndicator />;
        case 'Poltergeist':
            return <PoltergeistSpeedIndicator />;
        case 'Raiju':
            return <RaijuSpeedIndicator />;
        case 'Revenant':
            return <RevenantSpeedIndicator />;
        case 'Shade':
            return <ShadeSpeedIndicator />;
        case 'Spirit':
            return <SpiritSpeedIndicator />;
        case 'Thaye':
            return <ThayeSpeedIndicator />;
        case 'Twins':
            return <TwinsSpeedIndicator />;
        case 'Wraith':
            return <WraithSpeedIndicator />;
        case 'Yokai':
            return <YokaiSpeedIndicator />;
        case 'Yurei':
            return <YureiSpeedIndicator />;
    }
        return null;
}

function SanityIndicator({ ghostType }: { ghostType: GhostType }) {
    if (ghostType === 'Demon') {
        return <DemonSanityIndicator />;
    } else if (ghostType === 'Deogen') {
        return <DeogenSanityIndicator />;
    } else if (ghostType === 'Mare') {
        return <MareSanityIndicator />;
    } else if (ghostType === 'Onryo') {
        return <OnryoSanityIndicator />;
    } else if (ghostType === 'Raiju') {
        return <RaijuSanityIndicator />;
    } else if (ghostType === 'Shade') {
        return <ShadeSanityIndicator />;
    } else if (ghostType === 'Thaye') {
        return <ThayeSanityIndicator />;
    } else if (ghostType === 'Yokai') {
        return <YokaiSanityIndicator />;
    } else {
        return null;
    }
}