import { Evidence, EvidenceCount, PrimaryEvidenceType, SecondaryEvidenceType } from "./Evidence";
import { GhostType } from "./Ghost";
import { NoEvidenceResolver } from "./NoEvidenceResolver";
import { OneEvidenceResolver } from "./OneEvidenceResolver";
import { ThreeEvidenceResolver } from "./ThreeEvidenceResolver";
import { TwoEvidenceResolver } from "./TwoEvidenceResolver";

export interface EvidenceResolver {
    resolveEvidence: (type: PrimaryEvidenceType | SecondaryEvidenceType) => boolean | undefined;
    resolveGhost: (type: GhostType) => boolean;
}

export function evidenceResolver(count: EvidenceCount, evidences: Array<Evidence>): EvidenceResolver {
    switch (count) {
        case 3: return new ThreeEvidenceResolver(evidences);
        case 2: return new TwoEvidenceResolver(evidences);
        case 1: return new OneEvidenceResolver(evidences);
        case 0: return new NoEvidenceResolver(evidences);
    }
}
