import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Mare() {
    return (
        <div>
            <div>A Mare doesn't like light.</div>
            <div>Leave lights on in ghost room to lower hunt threshold to 40%. Once it's below 40% however turn off lights to lower the chance of the ghost to move to another room.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Can't <strong>turn on</strong> lights. However they can turn on TVs and Monitors.</li>
                        <li>Higher chance to roam into dark rooms, higher chance to change ghost room if lit.</li>
                        <li>Higher chance to burst lights.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    Has a small chance of turning a light off immediately after being turned on by a player.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <SanityIndicator sanity={60} /> or lower, if the light is <strong>turned off</strong>.</li>
                        <li>Hunts at <SanityIndicator sanity={40} /> or lower, if the light is <strong>turned on</strong>.</li>
                        <li>Candles do not affect the sanity threshold.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function MareSanityIndicator() {
    return <SanityIndicator sanity={60} />;
}

export function MareSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />;
}