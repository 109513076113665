import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";

export default function Mimic() {
    return (
        <div>
            <div>The Mimic is mimicking the traits of other ghosts.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /> <sup>1</sup></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /> <sup>2</sup></li>
                    </ul>
                    <div>
                        <sup>1</sup> The Mimic is the only ghost that may leave a UV
                        handprint on the <strong>front door</strong>, if it uses a Yurei's ability to close that door while also producing
                        the UV evidence of the Mimic.
                    </div>
                    <div>
                        <sup>2</sup> Ghost Orbs are always visible for the Mimic, which is not considered as one 
                        of the three main evidences.
                    </div>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    Mimicks the activity of other ghosts.
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    The Mimic is able to imiate any other ghost type. For evidence it can only mimic those 
                    that are evidence for the Mimic itself, like for example it can mimic the six-finger 
                    handprint of an Obake, but not the one appearing only on video for D.O.T.S. like the 
                    Goryo, since D.O.T.S. is not available as evidence for a Mimic.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    Mimicks the hunt behavior of other ghosts.
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function MimicSpeedIndicator() {
    return null;
}