import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 302, y: 725 },
    { type: 'Fusebox', id: 'fusebox2', x: 529, y: 681 },
    { type: 'Fusebox', id: 'fusebox3', x: 609, y: 279 },
    { type: 'Tarot Cards', id: 'tarot', x: 368, y: 727 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 357, y: 383 },
    { type: 'Monkey Paw', id: 'monkey', x: 347, y: 244 },
    { type: 'Haunted Mirror', id: 'mirror', x: 326, y: 724 },
    { type: 'Summoning Circle', id: 'circle', x: 867, y: 326 },
    { type: 'Music Box', id: 'musicbox', x: 494, y: 567 },
    { type: 'Ouija Board', id: 'ouija', x: 299, y: 678 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 652, y: 533 },
    { type: 'Tarot Cards', x: 652, y: 555 },
    { type: 'Voodoo Doll', x: 652, y: 580 },
    { type: 'Monkey Paw', x: 652, y: 601 },
    { type: 'Haunted Mirror', x: 652, y: 624 },
    { type: 'Summoning Circle', x: 652, y: 648 },
    { type: 'Music Box', x: 652, y: 669 },
    { type: 'Ouija Board', x: 652, y: 692 },
];

export default function BleasdaleFarmhouse() {
    return <InteractiveMap filename="maps/Bleasdale_Farmhouse_-_Floorplan.webp" width={1000} height={820} items={ITEMS} legend={LEGEND} />;
}