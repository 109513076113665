import { ALL_SECONDARY_EVIDENCE_TYPES } from "../types/Evidence";

import EvidenceIndicator from "./EvidenceIndicator";

export default function SecondaryEvidenceList() {
    const listItems = ALL_SECONDARY_EVIDENCE_TYPES.map(evidenceType => {
        return (
            <li className="list-group-item" key={evidenceType}>
                <EvidenceIndicator evidenceType={evidenceType} />
            </li>
        );
    });

    return (
        <section className="card m-1 w-100">
            <header className="card-header">
                <h5 className="card-title">Secondary Evidence</h5>
            </header>
            <main>
                <ul className="list-group list-group-flush">{listItems}</ul>
            </main>
        </section>
    );
}