export default function Paramic() {
    return (
        <div>
            <div>
                <strong>Myling:</strong> make paranormal sounds more often.
            </div>
            <div>
                <strong>Banshee:</strong> is singing or screeching.
            </div>
            <div>
                <strong>Moroi:</strong> is cursing the player when they hear the whisper.
            </div>
        </div>
    );
}