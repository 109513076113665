import { useState } from "react";

import SpiritBox from "./SpiritBox";
import EmfMeter from "./EmfMeter";
import Paramic from "./Paramic";

type GadgetType = 'Spirit Box' | 'EMF Meter' | 'Parabolic Mic';

export default function GadgetList() {
    const [selectedGadget, setSelectedGadget] = useState<GadgetType | undefined>();

    return (
        <section className="card m-1 w-100">
            <header className="card-header">
                <h5 className="card-title">Gadgets</h5>
            </header>
            <main className="card-body p-0">
                <div className="accordion accordion-flush">
                    <AccordionItem gadgetType="Spirit Box" selectedGadget={selectedGadget} setSelectedGadget={setSelectedGadget}>
                        <SpiritBox />
                    </AccordionItem>
                    <AccordionItem gadgetType="EMF Meter" selectedGadget={selectedGadget} setSelectedGadget={setSelectedGadget}>
                        <EmfMeter />
                    </AccordionItem>
                    <AccordionItem gadgetType="Parabolic Mic" selectedGadget={selectedGadget} setSelectedGadget={setSelectedGadget}>
                        <Paramic />
                    </AccordionItem>
                </div>
            </main>
        </section>
    );
}

function AccordionItem({ gadgetType, selectedGadget, setSelectedGadget, children }: { gadgetType: GadgetType, selectedGadget: GadgetType | undefined, setSelectedGadget: (gadgetType: GadgetType | undefined) => void, children: JSX.Element }) {
    const expanded = selectedGadget === gadgetType;
    const toggle = () => setSelectedGadget(expanded ? undefined : gadgetType);

    return (
        <section className="accordion-item">
            <header className="accordion-header">
                <button type="button" className={expanded ? "accordion-button" : "accordion-button collapsed"} onClick={toggle}>
                    {gadgetType}
                </button>
            </header>
            <main className={expanded ? "accordion-collapse" : "accordion-collapse collapse"}>
                <div className="accordion-body">
                    {children}
                </div>
            </main>
        </section>
    );
}