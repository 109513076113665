import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Myling() {
    return (
        <div>
            <div>A Myling is very talkative on <strong>paramic</strong>, but quiet during a hunt.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="EMF 5" /></li>
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Makes a lot of noise that can be heard using the <strong>paramic</strong></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Can only be heard when the ghost is near the player, footsteps are muffled when 12 m away and get quickly louder when nearing the player.</li>
                        <li>Strategy: when hunting, hide and drop the flashlight (turned on). Then when the ghost is only audible when the flashlight is flickering, it's probably a Myling.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function MylingSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}