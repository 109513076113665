import { useEffect, useRef, useState } from "react";
import { selectSpeed } from "../redux/EvidenceSlice";
import { useAppSelector } from "../redux/Hooks";

export type SpeedPredicate = (speed: number | null) => boolean;

export function speedExactly(speed: number, delta: number = 0.3): SpeedPredicate {
    return (measured) => {
        const lowerBound = speed - delta;
        const upperBound = speed + delta;
        return measured !== null && measured >= lowerBound && measured <= upperBound;
    };
}

export function speedInRange(from: number, to: number, delta: number = 0.3): SpeedPredicate {
    return (measured) => {
        const lowerBound = from - delta;
        const upperBound = to + delta;
        return measured !== null && measured >= lowerBound && measured <= upperBound;
    };
}

export function speedOr(lhs: SpeedPredicate, rhs: SpeedPredicate): SpeedPredicate {
    return (measured) => {
        if (lhs(measured)) {
            return true;
        } else if (rhs(measured)) {
            return true;
        } else {
            return false;
        }
    };
}

export default function SpeedIndicator({ speed }: { speed: SpeedPredicate }) {
    const [isEnabled, setEnabled] = useState(false);
    const measuredSpeed = useAppSelector(selectSpeed);
    const r = useRef<HTMLDivElement>(null);

    useEffect(() => setEnabled(speed(measuredSpeed)), [speed, measuredSpeed]);

    useEffect(() => {
        const parent = r.current?.parentElement;
        if (parent) {
            // parent.style.backgroundColor = isEnabled ? '#d00000' : 'transparent';
            if (isEnabled) {
                parent.classList.add('speed-highlighted');
            } else {
                parent.classList.remove('speed-highlighted');
            }
        }
    }, [r, isEnabled]);

    return (
        <span ref={r} className="float-end">&nbsp;</span>
    );
}
