export type MapName
    = '6 Tanglewood Drive'
    | '10 Ridgeview Court'
    | '13 Willow Street'
    | '42 Edgefield Road'
    | 'Bleasdale Farmhouse'
    | 'Camp Woodwind'
    | 'Grafton Farmhouse'
    | 'Sunny Meadows (Full)'
    | 'Sunny Meadows: Courtyard (Restricted)'
    | 'Sunny Meadows: Female Wing (Restricted)'
    | 'Sunny Meadows: Male Wing (Restricted)'
    | 'Sunny Meadows: Hospital Wing (Restricted)'
    | 'Sunny Meadows: Restricted Wing (Restricted)'
    | 'Maple Lodge Campsite'
    | 'Prison'
    | 'Brownstone High School';

export const ALL_MAP_NAMES: Array<MapName> = [
    '6 Tanglewood Drive',
    '10 Ridgeview Court',
    '13 Willow Street',
    '42 Edgefield Road',
    'Bleasdale Farmhouse',
    'Camp Woodwind',
    'Grafton Farmhouse',
    'Sunny Meadows (Full)',
    'Sunny Meadows: Courtyard (Restricted)',
    'Sunny Meadows: Female Wing (Restricted)',
    'Sunny Meadows: Male Wing (Restricted)',
    'Sunny Meadows: Hospital Wing (Restricted)',
    'Sunny Meadows: Restricted Wing (Restricted)',
    'Maple Lodge Campsite',
    'Prison',
    'Brownstone High School',
];
