import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 332, y: 706 },
    { type: 'Fusebox', id: 'fusebox2', x: 319, y: 402 },
    { type: 'Tarot Cards', id: 'tarot', x: 441, y: 214 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 441, y: 214 },
    { type: 'Monkey Paw', id: 'monkey', x: 441, y: 214 },
    { type: 'Haunted Mirror', id: 'mirror', x: 441, y: 214 },
    { type: 'Summoning Circle', id: 'circle', x: 441, y: 214 },
    { type: 'Music Box', id: 'musicbox', x: 441, y: 214 },
    { type: 'Ouija Board', id: 'ouija', x: 441, y: 214 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 631, y: 256 },
    { type: 'Tarot Cards', x: 631, y: 270 },
    { type: 'Voodoo Doll', x: 631, y: 285 },
    { type: 'Monkey Paw', x: 631, y: 300 },
    { type: 'Haunted Mirror', x: 631, y: 326 },
    { type: 'Summoning Circle', x: 631, y: 340 },
    { type: 'Music Box', x: 631, y: 356 },
    { type: 'Ouija Board', x: 631, y: 367 },
];

export default function SunnyMeadowsRestrictedWing() {
    return <InteractiveMap filename="maps/Sunny_Meadows_Restricted_Map_-_Restricted_Wing_Floorplan.webp" width={1000} height={867} items={ITEMS} legend={LEGEND} />;
}
