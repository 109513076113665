import { selectEvidenceResolver, updateEvidence } from "../redux/EvidenceSlice";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { PrimaryEvidenceType, SecondaryEvidenceType } from "../types/Evidence";

export default function EvidenceIndicator({ evidenceType }: { evidenceType: PrimaryEvidenceType | SecondaryEvidenceType }) {
    const dispatch = useAppDispatch();
    const evidenceResolver = useAppSelector(selectEvidenceResolver);
    const status = evidenceResolver.resolveEvidence(evidenceType);

    const toggle = () => {
        if (status === true) {
            dispatch(updateEvidence({ type: evidenceType, status: false }));
        } else if (status === false) {
            dispatch(updateEvidence({ type: evidenceType, status: undefined }));
        } else {
            dispatch(updateEvidence({ type: evidenceType, status: true }));
        }
    };

    return (
        <span onClick={toggle} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
            {status === true && <ConfirmedEvidence evidenceType={evidenceType} />}
            {status === false && <RuledOutEvidence evidenceType={evidenceType} />}
            {status === undefined && <IndeterminedEvidence evidenceType={evidenceType} />}
        </span>
    );
}

function ConfirmedEvidence({ evidenceType }: { evidenceType: PrimaryEvidenceType | SecondaryEvidenceType }) {
    return (
        <span className="fw-bold text-bg-dark">
            <i className="bi-check-circle-fill text-success"></i> {evidenceType}
        </span>
    );
}

function RuledOutEvidence({ evidenceType }: { evidenceType: PrimaryEvidenceType | SecondaryEvidenceType }) {
    return (
        <span className="text-decoration-line-through text-muted opacity-25">
            <i className="bi-x-circle"></i> {evidenceType}
        </span>
    );
}

function IndeterminedEvidence({ evidenceType }: { evidenceType: PrimaryEvidenceType | SecondaryEvidenceType }) {
    return (
        <span>
            <i className="bi-circle"></i> {evidenceType}
        </span>
    );
}
