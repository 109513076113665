export default function TarotCards() {
    return (
        <dl>
            <dt>Tower (20% chance)</dt>
            <dd>Causes an interaction and doubles all ghost activity for 20 seconds.</dd>

            <dt className="mt-4">Wheel of Fortune (20% chance)</dt>
            <dd>
                Gain or lose sanity.
                <ul>
                    <li><span className="text-success">green burn</span> 25% sanity gain</li>
                    <li><span className="text-danger">red burn</span> 25% sanity loss</li>
                </ul>
            </dd>

            <dt className="mt-4">Fool (17% chance)</dt>
            <dd>No effect.</dd>

            <dt className="mt-4">Devil (10% chance)</dt>
            <dd>Triggers a ghost event.</dd>

            <dt className="mt-4">Death (10% chance)</dt>
            <dd>Triggers a cursed hunt.</dd>

            <dt className="mt-4">Hermit (10% chance)</dt>
            <dd>Traps the ghost to its favorite room for one minute.</dd>

            <dt className="mt-4">Sun (5% chance)</dt>
            <dd>Restores sanity to 100%.</dd>

            <dt className="mt-4">Moon (5% chance)</dt>
            <dd>Removes sanity to 0%.</dd>

            <dt className="mt-4">High Priestess (2% chance)</dt>
            <dd>Revives a dead player.</dd>

            <dt className="mt-4">Hanged Man (1% chance)</dt>
            <dd>Instantly kills the player.</dd>
        </dl>
    );
}