import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedInRange } from "../SpeedIndicator";
import SpeedSymbol from "../SpeedSymbol";

export default function Thaye() {
    return (
        <div>
            <div>A Thaye will age when people are nearby, making it less interactive and dangerous.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                        <li><EvidendeIndicator evidenceType="D.O.T.S." /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    Activities depend on the age of the Thaye.
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    <div>
                        The Thaye will start young and age when people are nearby. Ageing has an affect on 
                        the hunt threshold and hunt speed, as well as the interaction and ghost event rate.
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Behavior/Age</th>
                                    <th className="text-end">0</th>
                                    <th className="text-end">+1</th>
                                    <th className="text-end">&ge; 10</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>hunt threshold</td>
                                    <td className="text-end">75%</td>
                                    <td className="text-end">-6%</td>
                                    <td className="text-end">15%</td>
                                </tr>
                                <tr>
                                    <td>hunt speed</td>
                                    <td className="text-end">2.75 m/s</td>
                                    <td className="text-end">-0.175 m/s</td>
                                    <td className="text-end">1 m/s</td>
                                </tr>
                                <tr>
                                    <td>interaction/event rate</td>
                                    <td className="text-end">200%</td>
                                    <td className="text-end">-15%</td>
                                    <td className="text-end">50%</td>
                                </tr>
                            </tbody>
                        </table>
                        The Ouija board can also be used to tell if a ghost has aged.
                    </div>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <SanityIndicator sanity={75} /> or lower when young, <SanityIndicator sanity={15} /> or lower when old.</li>
                        <li>Does <em>not</em> speed up when in line-of-sight.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function ThayeSanityIndicator() {
    return <SanityIndicator sanity={75} />;
}

export function ThayeSpeedIndicator() {
    return (
        <>
        <SpeedSymbol speed={2.75} description="fast when young" keepingSpeed />
        <SpeedSymbol speed={1.0} description="slow when old" keepingSpeed />
        <SpeedIndicator speed={speedInRange(1.0, 2.75)} />
        </>
    );
}
