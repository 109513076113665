import InteractiveMap, { MapItem, LegendItem } from "./InteractiveMap";

const ITEMS: Array<MapItem> = [
    { type: 'Fusebox', id: 'fusebox1', x: 201, y: 607 },
    { type: 'Fusebox', id: 'fusebox2', x: 615, y: 673 },
    { type: 'Fusebox', id: 'fusebox3', x: 642, y: 774 },
    { type: 'Key', id: 'key', x: 318, y: 831 },
    { type: 'Tarot Cards', id: 'tarot', x: 328, y: 831 },
    { type: 'Voodoo Doll', id: 'voodoo', x: 166, y: 798 },
    { type: 'Monkey Paw', id: 'monkey', x: 218, y: 288 },
    { type: 'Haunted Mirror', id: 'mirror', x: 222, y: 755 },
    { type: 'Summoning Circle', id: 'circle', x: 725, y: 748 },
    { type: 'Music Box', id: 'musicbox', x: 186, y: 409 },
    { type: 'Ouija Board', id: 'ouija', x: 206, y: 805 },
];

const LEGEND: Array<LegendItem> = [
    { type: 'Fusebox', x: 550, y: 226 },
    { type: 'Key', x: 550, y: 255 },
    { type: 'Tarot Cards', x: 550, y: 279 },
    { type: 'Voodoo Doll', x: 550, y: 309 },
    { type: 'Monkey Paw', x: 550, y: 337 },
    { type: 'Haunted Mirror', x: 550, y: 364 },
    { type: 'Summoning Circle', x: 550, y: 392 },
    { type: 'Music Box', x: 550, y: 421 },
    { type: 'Ouija Board', x: 550, y: 448 },
];

export default function RidgeviewCourt() {
    return <InteractiveMap filename="maps/10_Ridgeview_Court_-_Floorplan.webp" width={1000} height={933} items={ITEMS} legend={LEGEND} />;
}
