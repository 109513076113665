import { useCallback, useEffect, useRef, useState } from "react";

export type MapItemType = 'Fusebox' | 'Key' | 'Tarot Cards' | 'Voodoo Doll' | 'Monkey Paw' | 'Haunted Mirror' | 'Summoning Circle' | 'Music Box' | 'Ouija Board' | 'Light 1' | 'Light 2' | 'Light 3' | 'Light 4' | 'Light 5' | 'Light 6' | 'Light 7' | 'Light 8' | 'Light 9' | 'Switch 1' | 'Switch 2' | 'Switch 3' | 'Switch 4' | 'Switch 5' | 'Switch 6' | 'Switch 7' | 'Switch 8' | 'Switch 9';

export interface MapItem {
    type: MapItemType;
    id: string;
    x: number;
    y: number;
}

export interface LegendItem {
    type: MapItemType;
    x: number;
    y: number;
}

const DEFAULT_RADIUS = 10;

export default function InteractiveMap({ filename, width, height, items, legend, radius }: { filename: string, width: number, height: number, items: Array<MapItem>, legend: Array<LegendItem>, radius?: number }) {
    const [highlightedItems, setHighlightedItems] = useState<Array<MapItem>>([]);
    const [isInitialized, setInitialized] = useState(false);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const highlight = useCallback((items: Array<MapItem>) => {
        const contains = (item: MapItem, array: Array<MapItem>) => array.findIndex(next => next.id === item.id) !== -1;
        const added = items.filter(next => !contains(next, highlightedItems)).length;
        const removed = highlightedItems.filter(next => !contains(next, items)).length;
        const changed = added > 0 || removed > 0;

        if (changed) {
            setHighlightedItems(items);
        }
    }, [highlightedItems, setHighlightedItems]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const image = imageRef?.current;

        if (!!canvas && !!image && !isInitialized) {
            setInitialized(true);
            setTimeout(() => {
                canvas.style.width = width + 'px';
                canvas.style.height = height + 'px';
                canvas.style.opacity = '1';
                registerHoverEvent({ canvas, items, legend, highlight, radius: radius ?? DEFAULT_RADIUS });
            }, 500);
        }
    }, [canvasRef, imageRef, width, height, items, legend, radius, highlight, isInitialized, setInitialized]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const image = imageRef?.current;

        if (!!canvas && !!context && !!image) {
            setTimeout(() => {
                drawMap({ context, image, highlightedItems, legend, radius: radius ?? DEFAULT_RADIUS });
            }, 100);
        }
    }, [canvasRef, imageRef, legend, radius, highlightedItems]);

    return (
        <>
            <canvas ref={canvasRef} width={width} height={height} style={{ opacity: 0 }} />
            <img ref={imageRef} src={filename} width={width} height={height} alt="Map" style={{ opacity: 0 }} />
        </>
    );
}

function registerHoverEvent({ canvas, items, legend, radius, highlight }: { canvas: HTMLCanvasElement, items: Array<MapItem>, legend: Array<LegendItem>, radius: number, highlight: (items: Array<MapItem>) => void }) {
    canvas.addEventListener('mousemove', (event) => {
        const currentMapItem = items.find(item => {
            const fx0 = item.x - radius;
            const fx1 = item.x + radius;
            const fy0 = item.y - radius;
            const fy1 = item.y + radius;
    
            return event.offsetX >= fx0 && event.offsetX <= fx1 && event.offsetY >= fy0 && event.offsetY <= fy1;
        });

        const currentLegendItems = legend.filter(item => {
            const fx0 = item.x - radius;
            const fx1 = item.x + radius;
            const fy0 = item.y - radius;
            const fy1 = item.y + radius;
    
            return event.offsetX >= fx0 && event.offsetX <= fx1 && event.offsetY >= fy0 && event.offsetY <= fy1;
        });

        if (!!currentLegendItems.length) {
            const matches = (mapItem: MapItem) => currentLegendItems.findIndex(next => next.type === mapItem.type) !== -1;
            highlight(items.filter(item => matches(item)));
        } else if (!!currentMapItem) {
            highlight([currentMapItem]);
        } else {
            highlight([]);
        }
    });
}

function drawMap({ context, image, highlightedItems, legend, radius }: { context: CanvasRenderingContext2D, image: HTMLImageElement, highlightedItems: Array<MapItem>, legend: Array<LegendItem>, radius: number }) {
    context.drawImage(image, 0, 0);
    image.style.display = 'none';

    highlightedItems.forEach(mapItem => {
        const legendItem = legend.find(next => next.type === mapItem.type);

        const x = mapItem.x;
        const y = mapItem.y;
        const r = radius;

        const gradient = context.createRadialGradient(x, y, r * 0.25, x, y, r);
        gradient.addColorStop(0, 'red');
        gradient.addColorStop(1, 'white');

        context.lineWidth = 2;
        context.strokeStyle = 'red';
        context.fillStyle = gradient;

        context.beginPath();
        context.arc(x, y, r, 0, 2 * Math.PI, false);
        context.fill();

        if (!!legendItem) {
            context.moveTo(x, y);
            context.lineTo(legendItem.x, legendItem.y);
            context.stroke();
        }
    });
}