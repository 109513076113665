import { useCallback, useEffect, useState } from "react";
import { Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer } from "recharts";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { selectOptions, selectSpeed, updateSpeed } from "../redux/EvidenceSlice";
import calculateSpeed from "../types/Speed";

interface Footstep {
    time: number;
}

export default function Footsteps() {
    const dispatch = useAppDispatch();
    const factor = useAppSelector(selectOptions).speedFactor;
    const storedSpeed = useAppSelector(selectSpeed);
    const [footsteps, setFootsteps] = useState<Array<Footstep>>([]);

    const registerFootstep = useCallback(() => {
        const time = new Date().getTime();
        const footstep: Footstep = { time };
        setFootsteps([...footsteps, footstep]);
    }, [footsteps]);

    const resetFootsteps = useCallback(() => setFootsteps([]), []);

    const handleKey: EventListener = useCallback((event) => {
        if ('key' in event) {
            const key = `${event.key}`.toLowerCase();
            
            if (key === 'enter') {
                registerFootstep();
            } else if (key === 'backspace') {
                resetFootsteps();
            }
        }
    }, [registerFootstep, resetFootsteps]);

    useEffect(() => {
        window.addEventListener('keydown', handleKey);
        return () => window.removeEventListener('keydown', handleKey);
    }, [handleKey]);

    useEffect(() => {
        const bps = calculateBeatsPerSecond(footsteps);
        const mps = calculateSpeed(bps, factor);
        dispatch(updateSpeed(mps));
    }, [dispatch, storedSpeed, footsteps, factor]);

    if (footsteps.length < 1) {
        return null;
    }

    return (
        <section className="card m-1">
            <header className="card-header">
                <h5 className="card-title">Ghost Speed</h5>
            </header>
            <main className="p-2">
                <Diagram footsteps={footsteps} />
                <Stats footsteps={footsteps} />
            </main>
        </section>
    );
}

function Diagram({ footsteps }: { footsteps: Array<Footstep> }) {
    const factor = useAppSelector(selectOptions).speedFactor;

    const samples: Array<number> = [];
    for (let index = 1; index < footsteps.length; ++index) {
        const sample = footsteps.slice(0, index);
        const bps = calculateBeatsPerSecond(sample);
        const speed = calculateSpeed(bps, factor);
        samples.push(speed);
    }

    const latestSamples = samples.slice(-60);
    const data = chartData(latestSamples);

    if (!data.length) {
        return null;
    }

    return (
        <div className="footsteps-chart">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart width={800} height={75} data={data}>
                    <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} animationDuration={0.36} />
                    <ReferenceLine y={2.3} stroke="#a0a0a0" strokeDasharray="3 3" />
                    <ReferenceLine y={1.0} stroke="#a0a0a0" strokeDasharray="3 3" />
                    <ReferenceArea y1={0.0} y2={1.0} ifOverflow="visible" fill="green" />
                    <ReferenceArea y1={1.0} y2={2.0} ifOverflow="visible" fill="orange" />
                    <ReferenceArea y1={2.0} y2={100.0} ifOverflow="visible" fill="red" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

function Stats({ footsteps }: { footsteps: Array<Footstep> }) {
    const factor = useAppSelector(selectOptions).speedFactor;
    const bps = calculateBeatsPerSecond(footsteps);
    const mps = calculateSpeed(bps, factor);
    const formattedBpm = (bps * 60).toFixed(0);
    const formattedMps = mps.toFixed(2);

    if (!footsteps.length) {
        return null;
    }

    return <div className="ms-4 footsteps-stats" title="press Enter to add a step, press Backspace to reset.">{formattedBpm} bpm, {formattedMps} m/s</div>;
}

function calculateBeatsPerSecond(footsteps: Array<Footstep>): number {

    // Use only the last 30 footsteps.
    const latestFootsteps = footsteps.slice(-3);
    if (latestFootsteps.length < 2) {
        return 0;
    }

    // Calculate the duration between each pair of footsteps in seconds.
    const seconds: Array<number> = [];
    for (let index = 1; index < latestFootsteps.length; ++index) {
        const previous = latestFootsteps[index - 1];
        const current = latestFootsteps[index];
        seconds.push((current.time - previous.time) * 0.001);
    }

    // Calculate the average seconds and return the beats per seconds.
    const averageSeconds = seconds.reduce((a, b) => a + b, 0) / seconds.length;
    return averageSeconds <= 0 ? 0 : 1.0 / averageSeconds;
}

function chartData(samples: Array<number>): Array<any> {
    const data: Array<any> = [];

    for (let index = 0; index < samples.length; ++index) {
        const value = samples[index];
        data.push({ index, value });
    }

    return data;
}